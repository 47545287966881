import { DownloadOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from './ReportModal.module.css';
import { downloadReportFx } from '../../models/reportModel/index.js';
import { $userInfo } from '../../models/authModel/index.js';

export default function ReportArchive() {
  const downloadReport = useUnit(downloadReportFx);
  const userInfo = useUnit($userInfo);

  return (
    <div className={style.archive_wrapper}>
      {(userInfo.reports || []).map((item, index) => {
        return (
          <div className={style.archive_item} key={index}>
            <div>{item.filename}</div>
            <DownloadOutlined
              onClick={() =>
                downloadReport({ id: item.id, name: item.filename })
              }
            />
          </div>
        );
      })}
    </div>
  );
}
