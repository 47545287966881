import { Button, Card, Checkbox, InputNumber, Segmented, Slider } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { useState } from 'react';
import { useUnit } from 'effector-react';
import style from './IsochroneModal.module.css';
import { wsGetIsochrone } from '../../utils/webSocketConfig.js';
import {
  $isochroneStore,
  $isochroneTime,
  $isochroneType,
  $isTraffic,
  changeIsochroneCustomTimeEv,
  changeIsochroneTimeEv,
  changeIsochroneTypeEv,
  changeTrafficEv,
  clearIsochroneEv,
  getIsochroneByTimeEv,
} from '../../models/isochroneModel/index.js';
import { timeValues } from '../../dictionaries/isochrone_dict.js';
import { $drawMode } from '../../models/mapModel/index.js';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

export default function IsochroneModal() {
  // const show = useUnit($isochroneStore).length > 0;
  const drawMode = useUnit($drawMode);
  const isochroneStore = useUnit($isochroneStore);
  const show = drawMode === 'draw_point' || isochroneStore.length > 0;
  const clearIsochrone = useUnit(clearIsochroneEv);
  const isochroneTime = useUnit($isochroneTime);
  const isTraffic = useUnit($isTraffic);
  const isochroneType = useUnit($isochroneType);
  const changeIsochroneTime = useUnit(changeIsochroneTimeEv);
  const changeIsochroneCustomTime = useUnit(changeIsochroneCustomTimeEv);
  const getIsochroneByTime = useUnit(getIsochroneByTimeEv);
  const changeTraffic = useUnit(changeTrafficEv);
  const changeIsochroneType = useUnit(changeIsochroneTypeEv);
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);

  const segmentedOptions = [
    {
      label: ru_en_page_dictionary.isochrone_segmented_1[language],
      value: 'walk',
    },
    {
      label: ru_en_page_dictionary.isochrone_segmented_2[language],
      value: 'car',
    },
  ];

  const marks = {
    0: {
      style: { display: 'none' },
      label: `5 ${ru_en_page_dictionary.isochrone_time_measure[language]}`,
    },
    10: {
      style: { display: 'none' },
      label: `10 ${ru_en_page_dictionary.isochrone_time_measure[language]}`,
    },
    20: {
      style: { display: 'none' },
      label: `15 ${ru_en_page_dictionary.isochrone_time_measure[language]}`,
    },
    30: {
      style: { display: 'none' },
      label: `20 ${ru_en_page_dictionary.isochrone_time_measure[language]}`,
    },
    40: {
      style: { display: 'none' },
      label: `30 ${ru_en_page_dictionary.isochrone_time_measure[language]}`,
    },
  };

  // const changeIsochroneCategory = (value) => {
  //   setSegmentedValue(value);
  //   const feature = window.draw
  //     .getAll()
  //     .features.filter((item) => item.geometry.type === 'Point')[0];
  //   wsGetIsochrone({
  //     coordinates: feature.geometry.coordinates,
  //     //FIXME Category switch ???
  //     category: value
  //   })
  // };

  return (
    <Card className={[style.isochrone_modal, show ? style.show : ''].join(' ')}>
      <div className={style.isochrone_header}>
        <div
          className={style.isochrone_title}
          style={{ color: darkMode ? 'white' : 'black' }}
        >
          {ru_en_page_dictionary.isochrone_title[language]}
        </div>
        <Button
          style={{ border: 'none' }}
          icon={<CloseOutlined />}
          shape="circle"
          onClick={clearIsochrone}
        />
      </div>
      <Segmented
        style={{ marginTop: '20px', background: 'transparent' }}
        options={segmentedOptions}
        value={isochroneType}
        block
        onChange={changeIsochroneType}
      />
      <div className={style.isochrone_time_wrapper}>
        {Object.keys(marks).map((item, index) => {
          return (
            <div
              key={`isochrone_time_${index}`}
              className={style.isochrone_time_item}
            >
              {marks[item].label}
            </div>
          );
        })}
      </div>
      <Slider
        tooltip={{
          formatter: (value) =>
            `${timeValues[value]} ${ru_en_page_dictionary.isochrone_time_measure[language]}`,
          placement: 'bottom',
        }}
        style={{ marginTop: '12px', marginBottom: '11px' }}
        step={null}
        max={40}
        marks={marks}
        value={isochroneTime}
        onChange={changeIsochroneTime}
        onAfterChange={getIsochroneByTime}
      />
      {/* FIXME Custom time input setup */}
      {/* <InputNumber */}
      {/*  type="number" */}
      {/*  controls={false} */}
      {/*  placeholder={ru_en_page_dictionary.isochrone_custom_time[language]} */}
      {/*  className={style.custom_time_input} */}
      {/*  onChange={changeIsochroneCustomTime} */}
      {/* /> */}
      <Checkbox
        disabled={isochroneType === 'walk'}
        checked={isTraffic}
        onChange={(e) => changeTraffic(e.target.checked)}
      >
        {ru_en_page_dictionary.isochrone_traffic[language]}
      </Checkbox>
    </Card>
  );
}
