import TopBar from '../TopBar/TopBar.jsx';
import MetricTree from '../LeftBar/MetricTree.jsx';
import ResRadar from '../LeftBar/ResRadar.jsx';
import TabletRadar from '../LeftBar/TabletRadar.jsx';
import TabletReport from '../ReportModal/TabletReport.jsx';
import SideModal from '../SideModal/SideModal.jsx';
import TabletSideModal from '../SideModal/TabletSideModal.jsx';

export default function TabletContentManager(props) {
  const { activeTab } = props;

  switch (activeTab) {
    case 0:
      return (
        <div style={{ position: 'relative' }}>
          <TopBar />
          <TabletSideModal />
        </div>
      );
    case 1:
      return <MetricTree expanded />;
    case 2:
      return <TabletRadar />;
    case 3:
      return <TabletReport />;
    default:
      return <div> Nothing to display </div>;
  }
}
