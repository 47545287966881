export const all_metrics = [
  'index_101',
  'index_102',
  'index_103',
  'index_104',
  'index_105',
  'index_106',
  'index_107',
  'index_108',
  'index_109',
  'index_110',
  'index_111',
  'index_112',
  'index_113',
  'index_114',
  'index_115',
  'index_116',
  'index_201',
  'index_202',
  'index_203',
  'index_204',
  // 'index_205',
  'index_206',
  'index_207',
  'index_208',
  'index_209',
  'index_210',
  'index_211',
  'index_212',
  'index_213',
  'index_214',
  'index_215',
  'index_216',
  'index_217',
  'index_218',
  'index_219',
  'index_220',
  'index_301',
  // 'index_302',
  'index_303',
  'index_304',
  'index_305',
  'index_306',
  'index_307',
  'index_308',
  'index_309',
  'index_310',
  'index_311',
  'index_312',
  'index_401',
  'index_402',
  'index_403',
  // 'index_404',
  'index_405',
  'index_406',
  'index_407',
  'index_408',
  'index_409',
  // 'index_410',
  'index_411',
  'index_412',
  'index_501',
  'index_502',
  'index_503',
  'index_504',
  'index_505',
  'index_506',
  'index_507',
  'index_508',
  'index_509',
  'index_510',
  'index_511',
  'index_512',
  'index_513',
  'index_514',
  'index_515',
  'index_516',
];
