import notification from 'antd/es/notification';
import { districtsFilter, gradientFilter, polygonFilter } from './filters.js';

export default function filterHexagons(
  hexagons,
  activeFilters,
  isochroneStore
) {
  const filteredHexagons = hexagons.filter((item) => {
    if (!districtsFilter(activeFilters, item)) return false;
    if (!gradientFilter(activeFilters, item)) return false;
    if (!polygonFilter(activeFilters.draw_polygon, item)) return false;
    if (isochroneStore.length > 0) {
      if (!polygonFilter(isochroneStore[0], item)) return false;
    }
    return true;
  });

  notification.config({
    maxCount: 1,
    duration: 3,
  });

  if (filteredHexagons.length === 0) {
    notification.error({
      message: 'Error',
      description: 'При данной фильтрации результат 0 гексагонов',
      placement: 'topRight',
      className: 'notification',
    });

    return hexagons;
  }

  return filteredHexagons;
}
