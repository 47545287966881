import { Space } from 'antd';
import { useState } from 'react';
import style from './LeftBar.module.css';
import MetricTree from './MetricTree.jsx';
import ResRadar from './ResRadar.jsx';

export default function LeftBar() {
  const [expanded, setExpanded] = useState(['1']);

  const onExpand = (value) => {
    setExpanded(value);
  };

  return (
    <Space direction="vertical" className={style.left_bar} id="left_bar">
      <ResRadar onExpand={onExpand} expanded={expanded} />
      <MetricTree expanded={expanded} />
    </Space>
  );
}
