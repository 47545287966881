import {
  putFilteredZoom8HexagonsEv,
  putZoom8HexagonsEv,
} from '../models/zoom8Model/index.js';
import {
  putFilteredZoom9HexagonsEv,
  putZoom9HexagonsEv,
} from '../models/zoom9Model/index.js';
import {
  putFilteredZoom10HexagonsEv,
  putZoom10HexagonsEv,
} from '../models/zoom10Model/index.js';
import { hideLoaderEv, showLoaderEv } from '../models/webSocketModel/index.js';
import {
  putChartDataEv,
  putCityIndexEv,
  putFilteredChartDataEv,
  putFilteredIndexCardsDataEv,
  putIndexCardsDataEv,
} from '../models/indexCardsModel/index.js';
import { setUserInfoEv } from '../models/authModel/index.js';
import { cookies } from '../api/axiosinstance.js';
import { putSearchOptionsEv } from '../models/mapModel/index.js';
import { putIsochroneDataEv } from '../models/isochroneModel/index.js';
import { putBlockedBucketsEv } from '../models/gradientModel/index.js';

export function wsOnMessageHandler(message) {
  message = JSON.parse(message.data);
  console.log('WEBSOCKET:: wsOnMessageHandler: message', message);

  switch (message.action) {
    case 'users/login': {
      if (message.response.includes('expired token')) {
        cookies.remove('access_token', { path: '/' });
        // window.location.replace('/login');
      }
      break;
    }
    case 'geo/almaty/get_polygons':
      switch (message.part) {
        case 'zoom_8':
          // putZoom8HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_8',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_9':
          // putZoom9HexagonsEv(message.response.polygons);
          // putBlockedBucketsEv({
          //   zoom: 'zoom_9',
          //   value: message.response.gradient,
          // });
          break;
        case 'zoom_10':
          window.download_end = performance.now();
          window.mutate_start = performance.now();
          putZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          hideLoaderEv();
          break;
        case 'city_total':
          putIndexCardsDataEv(message.response);
          putChartDataEv(message.response);
          putCityIndexEv(message.response.index);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'geo/almaty/get_polygons_filtered':
      switch (message.part) {
        case 'zoom_8':
          putFilteredZoom8HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_8',
            value: message.response.gradient,
          });
          break;
        case 'zoom_9':
          putFilteredZoom9HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_9',
            value: message.response.gradient,
          });
          break;
        case 'zoom_10':
          putFilteredZoom10HexagonsEv(message.response.polygons);
          putBlockedBucketsEv({
            zoom: 'zoom_10',
            value: message.response.gradient,
          });
          hideLoaderEv();
          break;
        case 'city_total':
          putFilteredIndexCardsDataEv(message.response);
          putFilteredChartDataEv(message.response);
          break;
        default:
          console.log('Unknown part: ', message);
          break;
      }
      break;
    case 'users/info':
      setUserInfoEv(message.response);
      break;
    case 'geo/almaty/get_point':
      putSearchOptionsEv(message.response);
      break;
    case 'geo/almaty/get_isochrone':
      putIsochroneDataEv(message.response);
      break;
    default:
      break;
  }
}

export function wsGetAlmatyPolygons() {
  showLoaderEv();
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'geo/almaty/get_polygons',
      partial: true,
    })
  );
}

export function wsGetAlmatyPolygonsV2() {
  showLoaderEv();
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'geo/almaty/get_polygons_v2',
      partial: true,
    })
  );
}

export function wsSendAddFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/add',
      payload: {
        index_id: indexes,
      },
    })
  );
}
export function wsSendRemoveFavorites(indexes) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/favorite/remove',
      payload: {
        index_id: indexes,
      },
    })
  );
}

export function wsSendDarkTheme(dark_theme) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'user/set_dark_theme',
      payload: {
        enable: dark_theme,
      },
    })
  );
}

export function wsGetSearchOptions(place) {
  window.ws.send(
    JSON.stringify({
      id: 'geo',
      action: 'geo/almaty/get_point',
      payload: {
        address: place,
      },
      partial: false,
    })
  );
}

export function wsGetIsochrone(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'geo/almaty/get_isochrone',
      payload: {
        lat: payload.coordinates ? payload.coordinates[1] : null,
        lon: payload.coordinates ? payload.coordinates[0] : null,
        time: payload.time ? payload.time : 5,
        traffic: payload.traffic
          ? payload.type === 'walk'
            ? false
            : payload.traffic
          : false,
        type: payload.type ? payload.type : 'walk',
      },
    })
  );
}

export function wsGetFilteredPolygons(payload) {
  window.ws.send(
    JSON.stringify({
      action: 'geo/almaty/get_polygons_filtered',
      payload: {
        region_ids: payload.region_ids ? payload.region_ids : [],
        index_ids: payload.index_ids ? payload.index_ids : [],
      },
      partial: true,
    })
  );
}
