import { sample } from 'effector';
import {
  calculateThresholdsEv,
  changeBucketAlgorithmEv,
  putBlockedBucketsEv,
  putThresholdsEv,
  resetBlockedBucketsEv,
} from './events.js';
import {
  $blockedBuckets,
  $bucketAlgorithm,
  $gradientBuckets,
} from './stores.js';
import {
  $activeFilters,
  clearActiveGradientEv,
  selectAllEv,
} from '../activeFiltersModel/index.js';
import {
  $isDarkTheme,
  $userInfo,
  changeDarkThemeEv,
} from '../authModel/index.js';
import { getGeoBuckets, getThresholds } from '../../utils/gradient-utils.js';
import { $zoom10Hexagons } from '../zoom10Model/index.js';
import { bucketsByAlgorithmFx } from './effects.js';
import { delayedHideLoaderEv, showLoaderEv } from '../webSocketModel/index.js';

$blockedBuckets.reset(resetBlockedBucketsEv);

sample({
  source: $blockedBuckets,
  clock: putBlockedBucketsEv,
  fn: (source, clock) => {
    return {
      ...source,
      [clock.zoom]: clock.value,
    };
  },
  target: $blockedBuckets,
});

sample({
  clock: selectAllEv,
  target: resetBlockedBucketsEv,
});

sample({
  source: $gradientBuckets,
  clock: $isDarkTheme.updates,
  fn: (source, clock) => {
    let colors;
    if (clock) {
      colors = [
        // 'rgba(123, 35, 104, 1)',
        // 'rgba(162, 21, 74, 1)',
        // 'rgba(178, 41, 93, 1)',
        // 'rgba(213, 63, 66, 1)',
        // 'rgba(247, 53, 27, 1)',
        // 'rgba(207, 159, 14, 0.8)',
        // 'rgba(223, 143, 33, 0.8)',
        // 'rgba(247, 157, 27, 1)',
        // 'rgba(253, 215, 44, 1)',
        // 'rgba(254, 248, 173, 1)',

        // 'rgba(123, 35, 104, 0.4)',
        // 'rgba(162, 21, 74, 0.44)',
        // 'rgba(178, 41, 93, 0.49)',
        // 'rgba(213, 63, 66, 0.53)',
        // 'rgba(247, 53, 27, 0.58)',
        // 'rgba(207, 159, 14, 0.62)',
        // 'rgba(223, 143, 33, 0.67)',
        // 'rgba(247, 157, 27, 0.71)',
        // 'rgba(253, 215, 44, 0.76)',
        // 'rgba(254, 248, 173, 0.8)',

        'rgba(0, 0, 69, 1)',
        'rgba(27, 12, 66, 1)',
        'rgba(76, 12, 107, 1)',
        'rgba(121, 28, 110, 1)',
        'rgba(166, 45, 97, 1)',
        'rgba(207, 68, 71, 1)',
        'rgba(238, 105, 37, 1)',
        'rgba(252, 155, 6, 1)',
        'rgba(248, 209, 60, 1)',
        'rgba(253, 255, 165, 1)',
      ];
    } else {
      colors = [
        'rgba(255, 0, 0, 1)',
        'rgba(255, 57, 0, 1)',
        'rgba(255, 113, 0, 1)',
        'rgba(255, 170, 0, 1)',
        'rgba(255, 227, 0, 1)',
        'rgba(216, 233, 0, 1)',
        'rgba(148, 190, 0, 1)',
        'rgba(92, 147, 0, 1)',
        'rgba(49, 103, 13, 1)',
        'rgba(20, 60, 0, 1)',
      ];
    }
    return {
      ...source,
      colors,
    };
  },
  target: $gradientBuckets,
});

sample({
  source: [$userInfo, $bucketAlgorithm, $activeFilters],
  clock: calculateThresholdsEv,
  filter: ([userInfo, _, activeFilters]) =>
    userInfo.perms.includes('admin') && activeFilters.gradient.length === 0,
  fn: ([userInfo, algorithm], clock) => {
    return {
      hexagons: clock,
      algorithm,
    };
  },
  target: bucketsByAlgorithmFx,
});

sample({
  source: $gradientBuckets,
  clock: putThresholdsEv,
  fn: (source, clock) => {
    return {
      ...source,
      buckets: clock,
    };
  },
  target: $gradientBuckets,
});

sample({
  clock: changeBucketAlgorithmEv,
  fn: (clock) => clock,
  target: [$bucketAlgorithm, clearActiveGradientEv],
});

sample({
  source: $zoom10Hexagons,
  clock: $bucketAlgorithm.updates,
  fn: (hexagons, clock) => {
    return {
      hexagons,
      algorithm: clock,
    };
  },
  target: [bucketsByAlgorithmFx, showLoaderEv],
});

sample({
  clock: bucketsByAlgorithmFx.doneData,
  target: [putThresholdsEv, delayedHideLoaderEv],
});
