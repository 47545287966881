import { Card, Collapse, Divider, Spin } from 'antd';
import { useUnit } from 'effector-react';
import { DoubleRightOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { $radarLoader } from '../../models/webSocketModel/index.js';
import style from './LeftBar.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import RadarChartNew from '../RadarChart/RadarChartNew.jsx';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { $indexChartData } from '../../models/indexCardsModel/index.js';

export default function ResRadar(props) {
  const { onExpand, expanded } = props;
  const radarLoader = useUnit($radarLoader);
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);

  const indexChartData = useUnit($indexChartData);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const filteredChartData = indexChartData.filter((item) => item.value !== 0);
    setIndex(
      filteredChartData.reduce((sum, item) => sum + +item.value, 0) /
        filteredChartData.length || 0
    );
  }, [indexChartData]);

  const collapseItems = [
    {
      key: 1,
      label: (
        <div
          className={style.radar_title}
          style={{ color: darkMode ? 'white' : 'black' }}
        >
          {ru_en_page_dictionary.resilience_index[language]}: {index.toFixed(2)}{' '}
          / 100
          <Divider style={{ margin: 0, marginTop: '10px' }} />
        </div>
      ),
      // children: <RadarChart />,
      children: <RadarChartNew />,
    },
  ];

  const setExpandIcon = (panelProps) => {
    const { isActive } = panelProps;
    if (isActive) {
      return <DoubleRightOutlined rotate={90} />;
    }
    return <DoubleRightOutlined rotate={270} />;
  };

  return (
    <Spin spinning={radarLoader} size="large">
      <Card bodyStyle={{ padding: '0px 20px' }}>
        <Collapse
          ghost
          defaultActiveKey={1}
          items={collapseItems}
          expandIconPosition="end"
          expandIcon={setExpandIcon}
          onChange={onExpand}
          activeKey={expanded}
        />
      </Card>
    </Spin>
  );
}
