import {
  changeDrawModeEv,
  changeInteractivityEv,
} from '../models/mapModel/index.js';
import { changeActiveFilterEv } from '../models/activeFiltersModel/index.js';
import { showLoaderEv } from '../models/webSocketModel/index.js';
import { wsGetIsochrone } from './webSocketConfig.js';
import { getIsochroneByPointEv } from '../models/isochroneModel/index.js';

export const modeChangeHandler = (e) => {
  changeDrawModeEv(e.mode);
  if (e.mode === 'draw_polygon' || e.mode === 'draw_point') {
    changeInteractivityEv(false);
  }
  if (e.mode === 'simple_select') {
    setTimeout(() => {
      changeInteractivityEv(true);
    }, 100);
  }
};

export const createObject = (e) => {
  const mode = window.draw.getMode();
  const drawn_objects = window.draw.getAll().features;
  if (mode === 'draw_polygon') {
    console.log('created polygon', e.features);
    window.draw.set({
      type: 'FeatureCollection',
      features: [
        ...drawn_objects.filter((item) => item.geometry.type !== 'Polygon'),
        e.features[0],
      ],
    });
    showLoaderEv();
    changeActiveFilterEv({
      field: 'draw_polygon',
      value: e.features[0],
    });
  } else {
    console.log('created point', e.features);
    window.draw.set({
      type: 'FeatureCollection',
      features: [
        ...drawn_objects.filter((item) => item.geometry.type !== 'Point'),
        e.features[0],
      ],
    });
    getIsochroneByPointEv(e.features[0]);
  }
};

export const deleteAll = (e) => {
  window.draw.set({
    type: 'FeatureCollection',
    features: [],
  });
  changeActiveFilterEv({
    field: 'draw_polygon',
    value: {},
  });
};

// FIXME Custom style for polygons
export const customStylesDark = [
  // FIXME For lines while drawing polygon
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#ffffff',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  // FIXME For points of polygon
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#ffffff',
    },
  },
  // FIXME For outline of polygon while its active
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#ffffff',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  // FIXME For outline of polygon while its inactive
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#ffffff',
      'line-width': 3,
    },
  },
  // FIXME For point while its active
  {
    id: 'highlight-active-points',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#ffffff',
    },
  },
  // FIXME For point while its inactive
  {
    id: 'points-are-blue',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'false'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#ffffff',
    },
  },
];

export const customStylesLight = [
  // FIXME For lines while drawing polygon
  {
    id: 'gl-draw-line',
    type: 'line',
    filter: ['all', ['==', '$type', 'LineString'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  // FIXME For points of polygon
  {
    id: 'gl-draw-polygon-midpoint',
    type: 'circle',
    filter: ['all', ['==', '$type', 'Point'], ['==', 'meta', 'vertex']],
    paint: {
      'circle-radius': 3,
      'circle-color': '#242121',
    },
  },
  // FIXME For outline of polygon while its active
  {
    id: 'gl-draw-polygon-stroke-active',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['!=', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-dasharray': [0.2, 2],
      'line-width': 2,
    },
  },
  // FIXME For outline of polygon while its inactive
  {
    id: 'gl-draw-polygon-stroke-static',
    type: 'line',
    filter: ['all', ['==', '$type', 'Polygon'], ['==', 'mode', 'static']],
    layout: {
      'line-cap': 'round',
      'line-join': 'round',
    },
    paint: {
      'line-color': '#242121',
      'line-width': 3,
    },
  },
  // FIXME For point while its active
  {
    id: 'highlight-active-points',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'true'],
    ],
    paint: {
      'circle-radius': 7,
      'circle-color': '#242121',
    },
  },
  // FIXME For point while its inactive
  {
    id: 'points-are-blue',
    type: 'circle',
    filter: [
      'all',
      ['==', '$type', 'Point'],
      ['==', 'meta', 'feature'],
      ['==', 'active', 'false'],
    ],
    paint: {
      'circle-radius': 5,
      'circle-color': '#242121',
    },
  },
];

// FIXME Class for custom buttons (found in internet)
export class ExtendDrawBar {
  constructor(opt) {
    const ctrl = this;
    ctrl.draw = opt.draw;
    ctrl.buttons = opt.buttons || [];
    ctrl.onAddOrig = opt.draw.onAdd;
    ctrl.onRemoveOrig = opt.draw.onRemove;
  }

  onAdd(map) {
    const ctrl = this;
    ctrl.map = map;
    ctrl.elContainer = ctrl.onAddOrig(map);
    ctrl.buttons.forEach((b) => {
      ctrl.addButton(b);
    });
    return ctrl.elContainer;
  }

  onRemove(map) {
    const ctrl = this;
    ctrl.buttons.forEach((b) => {
      ctrl.removeButton(b);
    });
    ctrl.onRemoveOrig(map);
  }

  addButton(opt) {
    const ctrl = this;
    const elButton = document.createElement('button');
    elButton.className = 'mapbox-gl-draw_ctrl-draw-btn';
    if (opt.classes instanceof Array) {
      opt.classes.forEach((c) => {
        elButton.classList.add(c);
      });
    }
    elButton.addEventListener(opt.on, opt.action);
    ctrl.elContainer.appendChild(elButton);
    opt.elButton = elButton;
  }

  removeButton(opt) {
    opt.elButton.removeEventListener(opt.on, opt.action);
    opt.elButton.remove();
  }
}

export const changeDrawStyles = (darkMode) => {
  const source = darkMode ? customStylesDark : customStylesLight;

  source.forEach((layer) => {
    Object.entries(layer.paint).forEach(([property, value]) => {
      window.map.setPaintProperty(`${layer.id}.cold`, property, value);
      window.map.setPaintProperty(`${layer.id}.hot`, property, value);
    });
  });
};
