import { createEvent } from 'effector';

export const putZoom10HexagonsEv = createEvent();

export const formMutatedDict10Ev = createEvent();

export const resetZoom10ByMetricsEv = createEvent();

export const putCalculated10HexagonsEv = createEvent();

export const putFilteredZoom10HexagonsEv = createEvent();

export const filterZoom10GradientEv = createEvent();
