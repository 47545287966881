import { Button, Popover, Slider } from 'antd';
import { BorderInnerOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from './OpacitySlider.module.css';
import {
  $layersOpacity,
  changeLayersOpacityEv,
} from '../../models/mapModel/index.js';

export default function OpacitySlider() {
  const layersOpacity = useUnit($layersOpacity);
  const changeLayersOpacity = useUnit(changeLayersOpacityEv);

  const popoverSliderContent = () => {
    return (
      <div>
        <Slider
          min={0}
          max={1}
          step={0.1}
          defaultValue={0.5}
          onChange={changeLayersOpacity}
          value={layersOpacity}
        />
      </div>
    );
  };

  return (
    <Popover
      placement="right"
      title="Прозрачность"
      content={popoverSliderContent}
    >
      <Button
        className={style.button}
        icon={<BorderInnerOutlined className={style.button_icon} />}
      />
    </Popover>
  );
}
