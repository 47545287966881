import { createStore } from 'effector';

const initialState = {
  // buckets: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
  buckets: [],
  // colors: [
  //   'rgba(0, 102, 255, 0.5)',
  //   'rgba(0, 149, 255, 0.5)',
  //   'rgba(71, 178, 255, 0.5)',
  //   'rgba(94, 202, 239, 0.5)',
  //   'rgba(240, 216, 30, 0.5)',
  //   'rgba(255, 188, 0, 0.5)',
  //   'rgba(255, 137, 3, 0.5)',
  //   'rgba(255, 84, 0, 0.5)',
  //   'rgba(255, 43, 0, 0.5)',
  //   'rgba(255, 0, 0, 0.7)',
  // ],
  // colors: [
  //   'rgba(255, 0, 0, 0.2)',
  //   'rgba(255, 103, 0, 0.2)',
  //   'rgba(255, 159, 0, 0.2)',
  //   'rgba(255, 208, 0, 0.2)',
  //   'rgba(255, 255, 0, 0.4)',
  //   'rgba(234, 255, 0, 0.4)',
  //   'rgba(212, 255, 0, 0.4)',
  //   'rgba(189, 255, 0, 0.6)',
  //   'rgba(92, 153, 13, 0.6)',
  //   'rgba(20, 60, 0, 0.6)',
  // ],
  colors: [
    'rgba(255, 0, 0, 1)',
    'rgba(255, 57, 0, 1)',
    'rgba(255, 113, 0, 1)',
    'rgba(255, 170, 0, 1)',
    'rgba(255, 227, 0, 1)',
    'rgba(216, 233, 0, 1)',
    'rgba(148, 190, 0, 1)',
    'rgba(92, 147, 0, 1)',
    'rgba(49, 103, 13, 1)',
    'rgba(20, 60, 0, 1)',
  ],
  // fixme Vasya colors
  // colors: [
  //   'rgba(248, 93, 93, 0.4)',
  //   'rgba(244, 109, 72, 0.44)',
  //   'rgba(236, 126, 53, 0.49)',
  //   'rgba(223, 143, 33, 0.53)',
  //   'rgba(207, 159, 14, 0.58)',
  //   'rgba(187, 173, 8, 0.62)',
  //   'rgba(164, 187, 29, 0.67)',
  //   'rgba(136, 200, 55, 0.71)',
  //   'rgba(102, 210, 83, 0.76)',
  //   'rgba(46, 220, 115, 0.8)',
  // ],

  // FIXME Dark theme colors
  // 'rgba(254, 248, 173, 1)',
  // 'rgba(253, 215, 44, 1)',
  // 'rgba(247, 157, 27, 1)',
  // 'rgba(223, 143, 33, 0.8)',
  // 'rgba(207, 159, 14, 0.8)',
  // 'rgba(247, 53, 27, 1)',
  // 'rgba(213, 63, 66, 1)',
  // 'rgba(178, 41, 93, 1)',
  // 'rgba(162, 21, 74, 1)',
  // 'rgba(123, 35, 104, 1)',
  // FIXME With opacity
  // "rgba(123, 35, 104, 0.4)",
  // "rgba(162, 21, 74, 0.44)",
  // "rgba(178, 41, 93, 0.49)",
  // "rgba(213, 63, 66, 0.53)",
  // "rgba(247, 53, 27, 0.58)",
  // "rgba(207, 159, 14, 0.62)",
  // "rgba(223, 143, 33, 0.67)",
  // "rgba(247, 157, 27, 0.71)",
  // "rgba(253, 215, 44, 0.76)",
  // "rgba(254, 248, 173, 0.8)",
};

const blockedInitialState = {
  zoom_8: {
    '0-10': true,
    '10-20': true,
    '20-30': true,
    '30-40': true,
    '40-50': true,
    '50-60': true,
    '60-70': true,
    '70-80': true,
    '80-90': true,
    '90-100': true,
  },
  zoom_9: {
    '0-10': true,
    '10-20': true,
    '20-30': true,
    '30-40': true,
    '40-50': true,
    '50-60': true,
    '60-70': true,
    '70-80': true,
    '80-90': true,
    '90-100': true,
  },
  zoom_10: {
    '0-10': true,
    '10-20': true,
    '20-30': true,
    '30-40': true,
    '40-50': true,
    '50-60': true,
    '60-70': true,
    '70-80': true,
    '80-90': true,
    '90-100': true,
  },
};

export const $gradientBuckets = createStore(initialState);
$gradientBuckets.watch((state) => console.log('$gradientBuckets', state));

export const $blockedBuckets = createStore(blockedInitialState);
$blockedBuckets.watch((state) => console.log('$blockedBuckets', state));

export const $bucketAlgorithm = createStore('EQI');
$bucketAlgorithm.watch((state) => console.log('$bucketAlgorithm', state));
