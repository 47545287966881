import Chart from 'react-apexcharts';
import { useUnit } from 'effector-react';
import { useEffect, useRef } from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleFilled } from '@ant-design/icons';
import { $indexChartData } from '../../models/indexCardsModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import {
  $isDarkTheme,
  $isTablet,
  $language,
} from '../../models/authModel/index.js';
import style from './RadarChart.module.css';

export default function RadarChartNew(props) {
  const { showInfo } = props;

  const indexChartData = useUnit($indexChartData);
  const language = useUnit($language);
  const darkMode = useUnit($isDarkTheme);
  const isTablet = useUnit($isTablet);
  const chartRef = useRef();

  const options = {
    markers: {
      size: 7,
    },
    fill: {
      opacity: 0.5,
      type: 'gradient',
      gradient: {
        type: 'vertical',
        shadeIntensity: 1,
        colorStops: [
          {
            offset: 75,
            color: '#5199D1BF',
            opacity: 0.8,
          },
          {
            offset: 100,
            color: '#5199D15C',
            opacity: 0.1,
          },
        ],
      },
    },
    chart: {
      offsetY: 20,
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      show: false,
    },
    xaxis: {
      floating: true,
      categories: indexChartData.map((item) =>
        item.index_name ? `i${item.index_name?.split('index_')[1]}` : ''
      ),
      labels: {
        offsetX: 0,
        offsetY: 6,
        show: true,
        style: {
          colors: ['#2D9CDB', '#2D9CDB', '#2D9CDB', '#2D9CDB', '#2D9CDB'],
          fontSize: '25px',
          fontFamily: 'icomoon',
        },
      },
    },
    dataLabels: {
      offsetY: 20,
      enabled: true,
      background: {
        enabled: true,
        borderRadius: 2,
      },
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
      x: {
        formatter: (value, opts) => indexChartData[opts.dataPointIndex].title,
      },
    },
  };

  const series = [
    {
      name: ru_en_page_dictionary.radar_tooltip[language],
      data: indexChartData.map((item) => item.value),
    },
  ];

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current.chart.ctx.updateOptions(options);
    }
  }, [indexChartData, darkMode]);

  const genereateTooltipValue = () => {
    return (
      <div className={style.tooltip_wrapper}>
        {indexChartData.map((item) => {
          const index = item.index_name.split('index_')[1];
          return (
            <div
              key={`tooltip-${item.index_name}`}
              className={style.tooltip_item}
            >
              <div className={style.tooltip_icon}>{`i${index}`}</div>
              {item.title} - {item.value}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={style.radar_wrapper}>
      {showInfo !== false ? (
        <Tooltip
          title={genereateTooltipValue()}
          placement="bottomRight"
          arrow={false}
        >
          <QuestionCircleFilled className={style.question_icon} />
        </Tooltip>
      ) : (
        ''
      )}
      <Chart
        ref={chartRef}
        options={options}
        series={series}
        type="radar"
        width="100%"
        height={isTablet ? '267' : '298'}
        padding="15"
      />
    </div>
  );
}
