import { median } from 'mathjs';
import zoom8_9_10_connections from '../data/zoom8_9_10_connections.json';
import zoom8 from '../data/zoom8.json';
import zoom9 from '../data/zoom9.json';
import zoom10 from '../data/zoom10.json';
import { hasActiveFilters } from './active-filters-utils.js';

const index_1_subindexes = [
  'index_101',
  'index_102',
  'index_103',
  'index_104',
  'index_105',
  'index_106',
  'index_107',
  'index_108',
  'index_109',
  'index_110',
  'index_111',
  'index_112',
  'index_113',
  'index_114',
  'index_115',
  'index_116',
];
const index_2_subindexes = [
  'index_201',
  'index_202',
  'index_203',
  'index_204',
  // 'index_205',
  'index_206',
  'index_207',
  'index_208',
  'index_209',
  'index_210',
  'index_211',
  'index_212',
  'index_213',
  'index_214',
  'index_215',
  'index_216',
  'index_217',
  'index_218',
  'index_219',
  'index_220',
];
const index_3_subindexes = [
  'index_301',
  // 'index_302',
  'index_303',
  'index_304',
  'index_305',
  'index_306',
  'index_307',
  'index_308',
  'index_309',
  'index_310',
  'index_311',
  'index_312',
];
const index_4_subindexes = [
  'index_401',
  'index_402',
  'index_403',
  // 'index_404',
  'index_405',
  'index_406',
  'index_407',
  'index_408',
  'index_409',
  // 'index_410',
  'index_411',
  'index_412',
];
const index_5_subindexes = [
  'index_501',
  'index_502',
  'index_503',
  'index_504',
  'index_505',
  'index_506',
  'index_507',
  'index_508',
  'index_509',
  'index_510',
  'index_511',
  'index_512',
  'index_513',
  'index_514',
  'index_515',
  'index_516',
];

const non_urban_metrics = ['index_105'];

const sub_index_metrics_tree = {
  1: index_1_subindexes,
  2: index_2_subindexes,
  3: index_3_subindexes,
  4: index_4_subindexes,
  5: index_5_subindexes,
};

export const mutateHexagons = (hexagons, mode) => {
  let source;
  switch (mode) {
    case 'zoom_8':
      source = zoom8.features;
      break;
    case 'zoom_9':
      source = zoom9.features;
      break;
    case 'zoom_10':
      source = zoom10.features;
      break;
    default:
      break;
  }

  return hexagons
    .map((item) => {
      const properties = {
        hex_id: item.id,
        city_region_id: item.ctreg,
        index_main: typeof item.idx[0] === 'number' ? item.idx[0] : null,
        index_1: typeof item.idx[1] === 'number' ? item.idx[1] : null,
        index_2: typeof item.idx[2] === 'number' ? item.idx[2] : null,
        index_3: typeof item.idx[3] === 'number' ? item.idx[3] : null,
        index_4: typeof item.idx[4] === 'number' ? item.idx[4] : null,
        index_5: typeof item.idx[5] === 'number' ? item.idx[5] : null,
        index_101: typeof item.idx[101] === 'number' ? item.idx[101] : null,
        index_102: typeof item.idx[102] === 'number' ? item.idx[102] : null,
        index_103: typeof item.idx[103] === 'number' ? item.idx[103] : null,
        index_104: typeof item.idx[104] === 'number' ? item.idx[104] : null,
        index_105: typeof item.idx[105] === 'number' ? item.idx[105] : null,
        index_106: typeof item.idx[106] === 'number' ? item.idx[106] : null,
        index_107: typeof item.idx[107] === 'number' ? item.idx[107] : null,
        index_108: typeof item.idx[108] === 'number' ? item.idx[108] : null,
        index_109: typeof item.idx[109] === 'number' ? item.idx[109] : null,
        index_110: typeof item.idx[110] === 'number' ? item.idx[110] : null,
        index_111: typeof item.idx[111] === 'number' ? item.idx[111] : null,
        index_112: typeof item.idx[112] === 'number' ? item.idx[112] : null,
        index_113: typeof item.idx[113] === 'number' ? item.idx[113] : null,
        index_114: typeof item.idx[114] === 'number' ? item.idx[114] : null,
        index_115: typeof item.idx[115] === 'number' ? item.idx[115] : null,
        index_116: typeof item.idx[116] === 'number' ? item.idx[116] : null,
        index_201: typeof item.idx[201] === 'number' ? item.idx[201] : null,
        index_202: typeof item.idx[202] === 'number' ? item.idx[202] : null,
        index_203: typeof item.idx[203] === 'number' ? item.idx[203] : null,
        index_204: typeof item.idx[204] === 'number' ? item.idx[204] : null,
        // index_205: typeof item.idx[205] === 'number' ? item.idx[205] : null,
        index_206: typeof item.idx[206] === 'number' ? item.idx[206] : null,
        index_207: typeof item.idx[207] === 'number' ? item.idx[207] : null,
        index_208: typeof item.idx[208] === 'number' ? item.idx[208] : null,
        index_209: typeof item.idx[209] === 'number' ? item.idx[209] : null,
        index_210: typeof item.idx[210] === 'number' ? item.idx[210] : null,
        index_211: typeof item.idx[211] === 'number' ? item.idx[211] : null,
        index_212: typeof item.idx[212] === 'number' ? item.idx[212] : null,
        index_213: typeof item.idx[213] === 'number' ? item.idx[213] : null,
        index_214: typeof item.idx[214] === 'number' ? item.idx[214] : null,
        index_215: typeof item.idx[215] === 'number' ? item.idx[215] : null,
        index_216: typeof item.idx[216] === 'number' ? item.idx[216] : null,
        index_217: typeof item.idx[217] === 'number' ? item.idx[217] : null,
        index_218: typeof item.idx[218] === 'number' ? item.idx[218] : null,
        index_219: typeof item.idx[219] === 'number' ? item.idx[219] : null,
        index_220: typeof item.idx[220] === 'number' ? item.idx[220] : null,
        index_301: typeof item.idx[301] === 'number' ? item.idx[301] : null,
        // index_302: typeof item.idx[302] === 'number' ? item.idx[302] : null,
        index_303: typeof item.idx[303] === 'number' ? item.idx[303] : null,
        index_304: typeof item.idx[304] === 'number' ? item.idx[304] : null,
        index_305: typeof item.idx[305] === 'number' ? item.idx[305] : null,
        index_306: typeof item.idx[306] === 'number' ? item.idx[306] : null,
        index_307: typeof item.idx[307] === 'number' ? item.idx[307] : null,
        index_308: typeof item.idx[308] === 'number' ? item.idx[308] : null,
        index_309: typeof item.idx[309] === 'number' ? item.idx[309] : null,
        index_310: typeof item.idx[310] === 'number' ? item.idx[310] : null,
        index_311: typeof item.idx[311] === 'number' ? item.idx[311] : null,
        index_312: typeof item.idx[312] === 'number' ? item.idx[312] : null,
        index_401: typeof item.idx[401] === 'number' ? item.idx[401] : null,
        index_402: typeof item.idx[402] === 'number' ? item.idx[402] : null,
        index_403: typeof item.idx[403] === 'number' ? item.idx[403] : null,
        // index_404: typeof item.idx[404] === 'number' ? item.idx[404] : null,
        index_405: typeof item.idx[405] === 'number' ? item.idx[405] : null,
        index_406: typeof item.idx[406] === 'number' ? item.idx[406] : null,
        index_407: typeof item.idx[407] === 'number' ? item.idx[407] : null,
        index_408: typeof item.idx[408] === 'number' ? item.idx[408] : null,
        index_409: typeof item.idx[409] === 'number' ? item.idx[409] : null,
        // index_410: typeof item.idx[410] === 'number' ? item.idx[410] : null,
        index_411: typeof item.idx[411] === 'number' ? item.idx[411] : null,
        index_412: typeof item.idx[412] === 'number' ? item.idx[412] : null,
        index_501: typeof item.idx[501] === 'number' ? item.idx[501] : null,
        index_502: typeof item.idx[502] === 'number' ? item.idx[502] : null,
        index_503: typeof item.idx[503] === 'number' ? item.idx[503] : null,
        index_504: typeof item.idx[504] === 'number' ? item.idx[504] : null,
        index_505: typeof item.idx[505] === 'number' ? item.idx[505] : null,
        index_506: typeof item.idx[506] === 'number' ? item.idx[506] : null,
        index_507: typeof item.idx[507] === 'number' ? item.idx[507] : null,
        index_508: typeof item.idx[508] === 'number' ? item.idx[508] : null,
        index_509: typeof item.idx[509] === 'number' ? item.idx[509] : null,
        index_510: typeof item.idx[510] === 'number' ? item.idx[510] : null,
        index_511: typeof item.idx[511] === 'number' ? item.idx[511] : null,
        index_512: typeof item.idx[512] === 'number' ? item.idx[512] : null,
        index_513: typeof item.idx[513] === 'number' ? item.idx[513] : null,
        index_514: typeof item.idx[514] === 'number' ? item.idx[514] : null,
        index_515: typeof item.idx[515] === 'number' ? item.idx[515] : null,
        index_516: typeof item.idx[516] === 'number' ? item.idx[516] : null,
        // is_urban: !!item.urban,
      };
      const geometry = source.find(
        (feature) => feature.id === item.id
      )?.geometry;
      return {
        type: 'Feature',
        properties,
        id: item.zoom_id,
        geometry,
      };
    })
    .filter((item) => item.geometry);
};

const calculateIndex = (hexagon, filtered_indexes) => {
  if (filtered_indexes.length === 0) return null;
  let result = 0;
  let length = 0;
  filtered_indexes.forEach((index) => {
    if (hexagon.properties[index] !== null) {
      result += hexagon.properties[index];
      length += 1;
    }
  });
  return Number.isNaN(result / length) ? null : result / length;
};

const filterSubindexes = (subindexes, excludedIndexes) => {
  return subindexes.filter((subindex) => !excludedIndexes.includes(subindex));
};

export const calculateIndexesByMedian = (
  hexagons,
  excludedIndexes,
  activeFilters,
  total = false
) => {
  const result = {};

  const filtered_hexes = hexagons.filter((item) => item);

  if (total) {
    console.time('TIME:: zoom 10 hexes');
    const subindexes_to_change = [];
    if (excludedIndexes.length === 0 || hasActiveFilters(activeFilters)) {
      subindexes_to_change.push('1', '2', '3', '4', '5');
    } else {
      excludedIndexes.forEach((index) => {
        const subindex = index.split('_')[1][0];
        if (!subindexes_to_change.includes(subindex)) {
          subindexes_to_change.push(subindex);
        }
      });
    }

    filtered_hexes.forEach((hex) => {
      if (subindexes_to_change.includes('1')) {
        hex.index_101 !== null
          ? Array.isArray(result.index_101)
            ? result.index_101.push(hex.index_101)
            : (result.index_101 = [hex.index_101])
          : '';
        hex.index_102 !== null
          ? Array.isArray(result.index_102)
            ? result.index_102.push(hex.index_102)
            : (result.index_102 = [hex.index_102])
          : '';
        hex.index_103 !== null
          ? Array.isArray(result.index_103)
            ? result.index_103.push(hex.index_103)
            : (result.index_103 = [hex.index_103])
          : '';
        hex.index_104 !== null
          ? Array.isArray(result.index_104)
            ? result.index_104.push(hex.index_104)
            : (result.index_104 = [hex.index_104])
          : '';
        hex.index_105 !== null
          ? Array.isArray(result.index_105)
            ? result.index_105.push(hex.index_105)
            : (result.index_105 = [hex.index_105])
          : '';
        hex.index_106 !== null
          ? Array.isArray(result.index_106)
            ? result.index_106.push(hex.index_106)
            : (result.index_106 = [hex.index_106])
          : '';
        hex.index_107 !== null
          ? Array.isArray(result.index_107)
            ? result.index_107.push(hex.index_107)
            : (result.index_107 = [hex.index_107])
          : '';
        hex.index_108 !== null
          ? Array.isArray(result.index_108)
            ? result.index_108.push(hex.index_108)
            : (result.index_108 = [hex.index_108])
          : '';
        hex.index_109 !== null
          ? Array.isArray(result.index_109)
            ? result.index_109.push(hex.index_109)
            : (result.index_109 = [hex.index_109])
          : '';
        hex.index_110 !== null
          ? Array.isArray(result.index_110)
            ? result.index_110.push(hex.index_110)
            : (result.index_110 = [hex.index_110])
          : '';
        hex.index_111 !== null
          ? Array.isArray(result.index_111)
            ? result.index_111.push(hex.index_111)
            : (result.index_111 = [hex.index_111])
          : '';
        hex.index_112 !== null
          ? Array.isArray(result.index_112)
            ? result.index_112.push(hex.index_112)
            : (result.index_112 = [hex.index_112])
          : '';
        hex.index_113 !== null
          ? Array.isArray(result.index_113)
            ? result.index_113.push(hex.index_113)
            : (result.index_113 = [hex.index_113])
          : '';
        hex.index_114 !== null
          ? Array.isArray(result.index_114)
            ? result.index_114.push(hex.index_114)
            : (result.index_114 = [hex.index_114])
          : '';
        hex.index_115 !== null
          ? Array.isArray(result.index_115)
            ? result.index_115.push(hex.index_115)
            : (result.index_115 = [hex.index_115])
          : '';
        hex.index_116 !== null
          ? Array.isArray(result.index_116)
            ? result.index_116.push(hex.index_116)
            : (result.index_116 = [hex.index_116])
          : '';
      }
      if (subindexes_to_change.includes('2')) {
        hex.index_201 !== null
          ? Array.isArray(result.index_201)
            ? result.index_201.push(hex.index_201)
            : (result.index_201 = [hex.index_201])
          : '';
        hex.index_202 !== null
          ? Array.isArray(result.index_202)
            ? result.index_202.push(hex.index_202)
            : (result.index_202 = [hex.index_202])
          : '';
        hex.index_203 !== null
          ? Array.isArray(result.index_203)
            ? result.index_203.push(hex.index_203)
            : (result.index_203 = [hex.index_203])
          : '';
        hex.index_204 !== null
          ? Array.isArray(result.index_204)
            ? result.index_204.push(hex.index_204)
            : (result.index_204 = [hex.index_204])
          : '';
        hex.index_206 !== null
          ? Array.isArray(result.index_206)
            ? result.index_206.push(hex.index_206)
            : (result.index_206 = [hex.index_206])
          : '';
        hex.index_207 !== null
          ? Array.isArray(result.index_207)
            ? result.index_207.push(hex.index_207)
            : (result.index_207 = [hex.index_207])
          : '';
        hex.index_208 !== null
          ? Array.isArray(result.index_208)
            ? result.index_208.push(hex.index_208)
            : (result.index_208 = [hex.index_208])
          : '';
        hex.index_209 !== null
          ? Array.isArray(result.index_209)
            ? result.index_209.push(hex.index_209)
            : (result.index_209 = [hex.index_209])
          : '';
        hex.index_210 !== null
          ? Array.isArray(result.index_210)
            ? result.index_210.push(hex.index_210)
            : (result.index_210 = [hex.index_210])
          : '';
        hex.index_211 !== null
          ? Array.isArray(result.index_211)
            ? result.index_211.push(hex.index_211)
            : (result.index_211 = [hex.index_211])
          : '';
        hex.index_212 !== null
          ? Array.isArray(result.index_212)
            ? result.index_212.push(hex.index_212)
            : (result.index_212 = [hex.index_212])
          : '';
        hex.index_213 !== null
          ? Array.isArray(result.index_213)
            ? result.index_213.push(hex.index_213)
            : (result.index_213 = [hex.index_213])
          : '';
        hex.index_214 !== null
          ? Array.isArray(result.index_214)
            ? result.index_214.push(hex.index_214)
            : (result.index_214 = [hex.index_214])
          : '';
        hex.index_215 !== null
          ? Array.isArray(result.index_215)
            ? result.index_215.push(hex.index_215)
            : (result.index_215 = [hex.index_215])
          : '';
        hex.index_216 !== null
          ? Array.isArray(result.index_216)
            ? result.index_216.push(hex.index_216)
            : (result.index_216 = [hex.index_216])
          : '';
        hex.index_217 !== null
          ? Array.isArray(result.index_217)
            ? result.index_217.push(hex.index_217)
            : (result.index_217 = [hex.index_217])
          : '';
        hex.index_218 !== null
          ? Array.isArray(result.index_218)
            ? result.index_218.push(hex.index_218)
            : (result.index_218 = [hex.index_218])
          : '';
        hex.index_219 !== null
          ? Array.isArray(result.index_219)
            ? result.index_219.push(hex.index_219)
            : (result.index_219 = [hex.index_219])
          : '';
        hex.index_220 !== null
          ? Array.isArray(result.index_220)
            ? result.index_220.push(hex.index_220)
            : (result.index_220 = [hex.index_220])
          : '';
      }
      if (subindexes_to_change.includes('3')) {
        hex.index_301 !== null
          ? Array.isArray(result.index_301)
            ? result.index_301.push(hex.index_301)
            : (result.index_301 = [hex.index_301])
          : '';
        hex.index_303 !== null
          ? Array.isArray(result.index_303)
            ? result.index_303.push(hex.index_303)
            : (result.index_303 = [hex.index_303])
          : '';
        hex.index_304 !== null
          ? Array.isArray(result.index_304)
            ? result.index_304.push(hex.index_304)
            : (result.index_304 = [hex.index_304])
          : '';
        hex.index_305 !== null
          ? Array.isArray(result.index_305)
            ? result.index_305.push(hex.index_305)
            : (result.index_305 = [hex.index_305])
          : '';
        hex.index_306 !== null
          ? Array.isArray(result.index_306)
            ? result.index_306.push(hex.index_306)
            : (result.index_306 = [hex.index_306])
          : '';
        hex.index_307 !== null
          ? Array.isArray(result.index_307)
            ? result.index_307.push(hex.index_307)
            : (result.index_307 = [hex.index_307])
          : '';
        hex.index_308 !== null
          ? Array.isArray(result.index_308)
            ? result.index_308.push(hex.index_308)
            : (result.index_308 = [hex.index_308])
          : '';
        hex.index_309 !== null
          ? Array.isArray(result.index_309)
            ? result.index_309.push(hex.index_309)
            : (result.index_309 = [hex.index_309])
          : '';
        hex.index_310 !== null
          ? Array.isArray(result.index_310)
            ? result.index_310.push(hex.index_310)
            : (result.index_310 = [hex.index_310])
          : '';
        hex.index_311 !== null
          ? Array.isArray(result.index_311)
            ? result.index_311.push(hex.index_311)
            : (result.index_311 = [hex.index_311])
          : '';
        hex.index_312 !== null
          ? Array.isArray(result.index_312)
            ? result.index_312.push(hex.index_312)
            : (result.index_312 = [hex.index_312])
          : '';
      }
      if (subindexes_to_change.includes('4')) {
        hex.index_401 !== null
          ? Array.isArray(result.index_401)
            ? result.index_401.push(hex.index_401)
            : (result.index_401 = [hex.index_401])
          : '';
        hex.index_402 !== null
          ? Array.isArray(result.index_402)
            ? result.index_402.push(hex.index_402)
            : (result.index_402 = [hex.index_402])
          : '';
        hex.index_403 !== null
          ? Array.isArray(result.index_403)
            ? result.index_403.push(hex.index_403)
            : (result.index_403 = [hex.index_403])
          : '';
        hex.index_405 !== null
          ? Array.isArray(result.index_405)
            ? result.index_405.push(hex.index_405)
            : (result.index_405 = [hex.index_405])
          : '';
        hex.index_406 !== null
          ? Array.isArray(result.index_406)
            ? result.index_406.push(hex.index_406)
            : (result.index_406 = [hex.index_406])
          : '';
        hex.index_407 !== null
          ? Array.isArray(result.index_407)
            ? result.index_407.push(hex.index_407)
            : (result.index_407 = [hex.index_407])
          : '';
        hex.index_408 !== null
          ? Array.isArray(result.index_408)
            ? result.index_408.push(hex.index_408)
            : (result.index_408 = [hex.index_408])
          : '';
        hex.index_409 !== null
          ? Array.isArray(result.index_409)
            ? result.index_409.push(hex.index_409)
            : (result.index_409 = [hex.index_409])
          : '';
        hex.index_411 !== null
          ? Array.isArray(result.index_411)
            ? result.index_411.push(hex.index_411)
            : (result.index_411 = [hex.index_411])
          : '';
        hex.index_412 !== null
          ? Array.isArray(result.index_412)
            ? result.index_412.push(hex.index_412)
            : (result.index_412 = [hex.index_412])
          : '';
      }
      if (subindexes_to_change.includes('5')) {
        hex.index_501 !== null
          ? Array.isArray(result.index_501)
            ? result.index_501.push(hex.index_501)
            : (result.index_501 = [hex.index_501])
          : '';
        hex.index_502 !== null
          ? Array.isArray(result.index_502)
            ? result.index_502.push(hex.index_502)
            : (result.index_502 = [hex.index_502])
          : '';
        hex.index_503 !== null
          ? Array.isArray(result.index_503)
            ? result.index_503.push(hex.index_503)
            : (result.index_503 = [hex.index_503])
          : '';
        hex.index_504 !== null
          ? Array.isArray(result.index_504)
            ? result.index_504.push(hex.index_504)
            : (result.index_504 = [hex.index_504])
          : '';
        hex.index_505 !== null
          ? Array.isArray(result.index_505)
            ? result.index_505.push(hex.index_505)
            : (result.index_505 = [hex.index_505])
          : '';
        hex.index_506 !== null
          ? Array.isArray(result.index_506)
            ? result.index_506.push(hex.index_506)
            : (result.index_506 = [hex.index_506])
          : '';
        hex.index_507 !== null
          ? Array.isArray(result.index_507)
            ? result.index_507.push(hex.index_507)
            : (result.index_507 = [hex.index_507])
          : '';
        hex.index_508 !== null
          ? Array.isArray(result.index_508)
            ? result.index_508.push(hex.index_508)
            : (result.index_508 = [hex.index_508])
          : '';
        hex.index_509 !== null
          ? Array.isArray(result.index_509)
            ? result.index_509.push(hex.index_509)
            : (result.index_509 = [hex.index_509])
          : '';
        hex.index_510 !== null
          ? Array.isArray(result.index_510)
            ? result.index_510.push(hex.index_510)
            : (result.index_510 = [hex.index_510])
          : '';
        hex.index_511 !== null
          ? Array.isArray(result.index_511)
            ? result.index_511.push(hex.index_511)
            : (result.index_511 = [hex.index_511])
          : '';
        hex.index_512 !== null
          ? Array.isArray(result.index_512)
            ? result.index_512.push(hex.index_512)
            : (result.index_512 = [hex.index_512])
          : '';
        hex.index_513 !== null
          ? Array.isArray(result.index_513)
            ? result.index_513.push(hex.index_513)
            : (result.index_513 = [hex.index_513])
          : '';
        hex.index_514 !== null
          ? Array.isArray(result.index_514)
            ? result.index_514.push(hex.index_514)
            : (result.index_514 = [hex.index_514])
          : '';
        hex.index_515 !== null
          ? Array.isArray(result.index_515)
            ? result.index_515.push(hex.index_515)
            : (result.index_515 = [hex.index_515])
          : '';
        hex.index_516 !== null
          ? Array.isArray(result.index_516)
            ? result.index_516.push(hex.index_516)
            : (result.index_516 = [hex.index_516])
          : '';
      }
    });
    Object.keys(result).forEach((key) => {
      if (result[key].length === 0) {
        result[key] = null;
      } else {
        // result[key] = median(result[key]);
        result[key] =
          result[key].reduce((a, b) => a + b, 0) / result[key].length;
      }
    });

    let index_main_length = 0;
    let index_main = 0;
    subindexes_to_change.forEach((item) => {
      let length = 0;
      const sum = sub_index_metrics_tree[item].reduce((a, b) => {
        if (b !== 'index_215' && b !== 'index_216') {
          if (result[b] !== null && result[b] !== undefined) {
            length += 1;
            return a + result[b];
          }
        }
        return a;
      }, 0);
      const avg = length > 0 ? sum / length : null;

      result[`index_${item}`] = avg;
      index_main += avg !== null ? avg : 0;
      if (avg !== null) index_main_length += 1;
    });
    console.timeEnd('TIME:: zoom 10 hexes');
  } else {
    filtered_hexes.forEach((hex) => {
      hex.index_101 !== null
        ? Array.isArray(result.index_101)
          ? result.index_101.push(hex.index_101)
          : (result.index_101 = [hex.index_101])
        : '';
      hex.index_102 !== null
        ? Array.isArray(result.index_102)
          ? result.index_102.push(hex.index_102)
          : (result.index_102 = [hex.index_102])
        : '';
      hex.index_103 !== null
        ? Array.isArray(result.index_103)
          ? result.index_103.push(hex.index_103)
          : (result.index_103 = [hex.index_103])
        : '';
      hex.index_104 !== null
        ? Array.isArray(result.index_104)
          ? result.index_104.push(hex.index_104)
          : (result.index_104 = [hex.index_104])
        : '';
      hex.index_105 !== null
        ? Array.isArray(result.index_105)
          ? result.index_105.push(hex.index_105)
          : (result.index_105 = [hex.index_105])
        : '';
      hex.index_106 !== null
        ? Array.isArray(result.index_106)
          ? result.index_106.push(hex.index_106)
          : (result.index_106 = [hex.index_106])
        : '';
      hex.index_107 !== null
        ? Array.isArray(result.index_107)
          ? result.index_107.push(hex.index_107)
          : (result.index_107 = [hex.index_107])
        : '';
      hex.index_108 !== null
        ? Array.isArray(result.index_108)
          ? result.index_108.push(hex.index_108)
          : (result.index_108 = [hex.index_108])
        : '';
      hex.index_109 !== null
        ? Array.isArray(result.index_109)
          ? result.index_109.push(hex.index_109)
          : (result.index_109 = [hex.index_109])
        : '';
      hex.index_110 !== null
        ? Array.isArray(result.index_110)
          ? result.index_110.push(hex.index_110)
          : (result.index_110 = [hex.index_110])
        : '';
      hex.index_111 !== null
        ? Array.isArray(result.index_111)
          ? result.index_111.push(hex.index_111)
          : (result.index_111 = [hex.index_111])
        : '';
      hex.index_112 !== null
        ? Array.isArray(result.index_112)
          ? result.index_112.push(hex.index_112)
          : (result.index_112 = [hex.index_112])
        : '';
      hex.index_113 !== null
        ? Array.isArray(result.index_113)
          ? result.index_113.push(hex.index_113)
          : (result.index_113 = [hex.index_113])
        : '';
      hex.index_114 !== null
        ? Array.isArray(result.index_114)
          ? result.index_114.push(hex.index_114)
          : (result.index_114 = [hex.index_114])
        : '';
      hex.index_115 !== null
        ? Array.isArray(result.index_115)
          ? result.index_115.push(hex.index_115)
          : (result.index_115 = [hex.index_115])
        : '';
      hex.index_116 !== null
        ? Array.isArray(result.index_116)
          ? result.index_116.push(hex.index_116)
          : (result.index_116 = [hex.index_116])
        : '';
      hex.index_201 !== null
        ? Array.isArray(result.index_201)
          ? result.index_201.push(hex.index_201)
          : (result.index_201 = [hex.index_201])
        : '';
      hex.index_202 !== null
        ? Array.isArray(result.index_202)
          ? result.index_202.push(hex.index_202)
          : (result.index_202 = [hex.index_202])
        : '';
      hex.index_203 !== null
        ? Array.isArray(result.index_203)
          ? result.index_203.push(hex.index_203)
          : (result.index_203 = [hex.index_203])
        : '';
      hex.index_204 !== null
        ? Array.isArray(result.index_204)
          ? result.index_204.push(hex.index_204)
          : (result.index_204 = [hex.index_204])
        : '';
      hex.index_206 !== null
        ? Array.isArray(result.index_206)
          ? result.index_206.push(hex.index_206)
          : (result.index_206 = [hex.index_206])
        : '';
      hex.index_207 !== null
        ? Array.isArray(result.index_207)
          ? result.index_207.push(hex.index_207)
          : (result.index_207 = [hex.index_207])
        : '';
      hex.index_208 !== null
        ? Array.isArray(result.index_208)
          ? result.index_208.push(hex.index_208)
          : (result.index_208 = [hex.index_208])
        : '';
      hex.index_209 !== null
        ? Array.isArray(result.index_209)
          ? result.index_209.push(hex.index_209)
          : (result.index_209 = [hex.index_209])
        : '';
      hex.index_210 !== null
        ? Array.isArray(result.index_210)
          ? result.index_210.push(hex.index_210)
          : (result.index_210 = [hex.index_210])
        : '';
      hex.index_211 !== null
        ? Array.isArray(result.index_211)
          ? result.index_211.push(hex.index_211)
          : (result.index_211 = [hex.index_211])
        : '';
      hex.index_212 !== null
        ? Array.isArray(result.index_212)
          ? result.index_212.push(hex.index_212)
          : (result.index_212 = [hex.index_212])
        : '';
      hex.index_213 !== null
        ? Array.isArray(result.index_213)
          ? result.index_213.push(hex.index_213)
          : (result.index_213 = [hex.index_213])
        : '';
      hex.index_214 !== null
        ? Array.isArray(result.index_214)
          ? result.index_214.push(hex.index_214)
          : (result.index_214 = [hex.index_214])
        : '';
      hex.index_215 !== null
        ? Array.isArray(result.index_215)
          ? result.index_215.push(hex.index_215)
          : (result.index_215 = [hex.index_215])
        : '';
      hex.index_216 !== null
        ? Array.isArray(result.index_216)
          ? result.index_216.push(hex.index_216)
          : (result.index_216 = [hex.index_216])
        : '';
      hex.index_217 !== null
        ? Array.isArray(result.index_217)
          ? result.index_217.push(hex.index_217)
          : (result.index_217 = [hex.index_217])
        : '';
      hex.index_218 !== null
        ? Array.isArray(result.index_218)
          ? result.index_218.push(hex.index_218)
          : (result.index_218 = [hex.index_218])
        : '';
      hex.index_219 !== null
        ? Array.isArray(result.index_219)
          ? result.index_219.push(hex.index_219)
          : (result.index_219 = [hex.index_219])
        : '';
      hex.index_220 !== null
        ? Array.isArray(result.index_220)
          ? result.index_220.push(hex.index_220)
          : (result.index_220 = [hex.index_220])
        : '';
      hex.index_301 !== null
        ? Array.isArray(result.index_301)
          ? result.index_301.push(hex.index_301)
          : (result.index_301 = [hex.index_301])
        : '';
      hex.index_303 !== null
        ? Array.isArray(result.index_303)
          ? result.index_303.push(hex.index_303)
          : (result.index_303 = [hex.index_303])
        : '';
      hex.index_304 !== null
        ? Array.isArray(result.index_304)
          ? result.index_304.push(hex.index_304)
          : (result.index_304 = [hex.index_304])
        : '';
      hex.index_305 !== null
        ? Array.isArray(result.index_305)
          ? result.index_305.push(hex.index_305)
          : (result.index_305 = [hex.index_305])
        : '';
      hex.index_306 !== null
        ? Array.isArray(result.index_306)
          ? result.index_306.push(hex.index_306)
          : (result.index_306 = [hex.index_306])
        : '';
      hex.index_307 !== null
        ? Array.isArray(result.index_307)
          ? result.index_307.push(hex.index_307)
          : (result.index_307 = [hex.index_307])
        : '';
      hex.index_308 !== null
        ? Array.isArray(result.index_308)
          ? result.index_308.push(hex.index_308)
          : (result.index_308 = [hex.index_308])
        : '';
      hex.index_309 !== null
        ? Array.isArray(result.index_309)
          ? result.index_309.push(hex.index_309)
          : (result.index_309 = [hex.index_309])
        : '';
      hex.index_310 !== null
        ? Array.isArray(result.index_310)
          ? result.index_310.push(hex.index_310)
          : (result.index_310 = [hex.index_310])
        : '';
      hex.index_311 !== null
        ? Array.isArray(result.index_311)
          ? result.index_311.push(hex.index_311)
          : (result.index_311 = [hex.index_311])
        : '';
      hex.index_312 !== null
        ? Array.isArray(result.index_312)
          ? result.index_312.push(hex.index_312)
          : (result.index_312 = [hex.index_312])
        : '';
      hex.index_401 !== null
        ? Array.isArray(result.index_401)
          ? result.index_401.push(hex.index_401)
          : (result.index_401 = [hex.index_401])
        : '';
      hex.index_402 !== null
        ? Array.isArray(result.index_402)
          ? result.index_402.push(hex.index_402)
          : (result.index_402 = [hex.index_402])
        : '';
      hex.index_403 !== null
        ? Array.isArray(result.index_403)
          ? result.index_403.push(hex.index_403)
          : (result.index_403 = [hex.index_403])
        : '';
      hex.index_405 !== null
        ? Array.isArray(result.index_405)
          ? result.index_405.push(hex.index_405)
          : (result.index_405 = [hex.index_405])
        : '';
      hex.index_406 !== null
        ? Array.isArray(result.index_406)
          ? result.index_406.push(hex.index_406)
          : (result.index_406 = [hex.index_406])
        : '';
      hex.index_407 !== null
        ? Array.isArray(result.index_407)
          ? result.index_407.push(hex.index_407)
          : (result.index_407 = [hex.index_407])
        : '';
      hex.index_408 !== null
        ? Array.isArray(result.index_408)
          ? result.index_408.push(hex.index_408)
          : (result.index_408 = [hex.index_408])
        : '';
      hex.index_409 !== null
        ? Array.isArray(result.index_409)
          ? result.index_409.push(hex.index_409)
          : (result.index_409 = [hex.index_409])
        : '';
      hex.index_411 !== null
        ? Array.isArray(result.index_411)
          ? result.index_411.push(hex.index_411)
          : (result.index_411 = [hex.index_411])
        : '';
      hex.index_412 !== null
        ? Array.isArray(result.index_412)
          ? result.index_412.push(hex.index_412)
          : (result.index_412 = [hex.index_412])
        : '';
      hex.index_501 !== null
        ? Array.isArray(result.index_501)
          ? result.index_501.push(hex.index_501)
          : (result.index_501 = [hex.index_501])
        : '';
      hex.index_502 !== null
        ? Array.isArray(result.index_502)
          ? result.index_502.push(hex.index_502)
          : (result.index_502 = [hex.index_502])
        : '';
      hex.index_503 !== null
        ? Array.isArray(result.index_503)
          ? result.index_503.push(hex.index_503)
          : (result.index_503 = [hex.index_503])
        : '';
      hex.index_504 !== null
        ? Array.isArray(result.index_504)
          ? result.index_504.push(hex.index_504)
          : (result.index_504 = [hex.index_504])
        : '';
      hex.index_505 !== null
        ? Array.isArray(result.index_505)
          ? result.index_505.push(hex.index_505)
          : (result.index_505 = [hex.index_505])
        : '';
      hex.index_506 !== null
        ? Array.isArray(result.index_506)
          ? result.index_506.push(hex.index_506)
          : (result.index_506 = [hex.index_506])
        : '';
      hex.index_507 !== null
        ? Array.isArray(result.index_507)
          ? result.index_507.push(hex.index_507)
          : (result.index_507 = [hex.index_507])
        : '';
      hex.index_508 !== null
        ? Array.isArray(result.index_508)
          ? result.index_508.push(hex.index_508)
          : (result.index_508 = [hex.index_508])
        : '';
      hex.index_509 !== null
        ? Array.isArray(result.index_509)
          ? result.index_509.push(hex.index_509)
          : (result.index_509 = [hex.index_509])
        : '';
      hex.index_510 !== null
        ? Array.isArray(result.index_510)
          ? result.index_510.push(hex.index_510)
          : (result.index_510 = [hex.index_510])
        : '';
      hex.index_511 !== null
        ? Array.isArray(result.index_511)
          ? result.index_511.push(hex.index_511)
          : (result.index_511 = [hex.index_511])
        : '';
      hex.index_512 !== null
        ? Array.isArray(result.index_512)
          ? result.index_512.push(hex.index_512)
          : (result.index_512 = [hex.index_512])
        : '';
      hex.index_513 !== null
        ? Array.isArray(result.index_513)
          ? result.index_513.push(hex.index_513)
          : (result.index_513 = [hex.index_513])
        : '';
      hex.index_514 !== null
        ? Array.isArray(result.index_514)
          ? result.index_514.push(hex.index_514)
          : (result.index_514 = [hex.index_514])
        : '';
      hex.index_515 !== null
        ? Array.isArray(result.index_515)
          ? result.index_515.push(hex.index_515)
          : (result.index_515 = [hex.index_515])
        : '';
      hex.index_516 !== null
        ? Array.isArray(result.index_516)
          ? result.index_516.push(hex.index_516)
          : (result.index_516 = [hex.index_516])
        : '';
    });
    Object.keys(result).forEach((key) => {
      if (result[key].length === 0) {
        result[key] = null;
      } else if (
        activeFilters.district.length > 0 ||
        Object.keys(activeFilters.draw_polygon).length > 0
      ) {
        result[key] =
          result[key].reduce((a, b) => a + b, 0) / result[key].length;
      } else {
        result[key] = median(result[key]);
      }
    });

    // index_1_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_2_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_3_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_4_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });
    // index_5_subindexes.forEach((index) => {
    //   const arrayForMedian = filtered_hexes
    //     .filter((hex) => hex[index] !== null)
    //     .map((item) => item[index]);
    //   if (arrayForMedian.length !== 0) {
    //     result[index] = median(arrayForMedian);
    //   } else {
    //     result[index] = null;
    //   }
    // });

    let index_main_length = 0;
    let index_main = 0;
    [1, 2, 3, 4, 5].forEach((item) => {
      let length = 0;
      const sum = sub_index_metrics_tree[item].reduce((a, b) => {
        if (b !== 'index_215' && b !== 'index_216') {
          if (result[b] !== null && result[b] !== undefined) {
            length += 1;
            return a + result[b];
          }
        }
        return a;
      }, 0);

      const avg = length > 0 ? sum / length : null;

      result[`index_${item}`] = avg;
      index_main += avg !== null ? avg : 0;
      if (avg !== null) index_main_length += 1;
    });

    result.index_main = index_main / index_main_length;
  }

  return result;
};

export const calculate10Hexagons = (zoom10Hexagons, excludedIndexes) => {
  // FIXME Remove timeStamps
  // console.time('TIME:: 10 Hexagons mutation');
  const filtered_index_1_subindexes = filterSubindexes(
    index_1_subindexes,
    excludedIndexes
  );
  const filtered_index_2_subindexes = filterSubindexes(
    index_2_subindexes,
    excludedIndexes
  ).filter((item) => item !== 'index_215' && item !== 'index_216');
  const filtered_index_3_subindexes = filterSubindexes(
    index_3_subindexes,
    excludedIndexes
  );
  const filtered_index_4_subindexes = filterSubindexes(
    index_4_subindexes,
    excludedIndexes
  );
  const filtered_index_5_subindexes = filterSubindexes(
    index_5_subindexes,
    excludedIndexes
  );

  const result = zoom10Hexagons.map((hexagon) => {
    const index_1 = calculateIndex(hexagon, filtered_index_1_subindexes);
    const index_2 = calculateIndex(hexagon, filtered_index_2_subindexes);
    const index_3 = calculateIndex(hexagon, filtered_index_3_subindexes);
    const index_4 = calculateIndex(hexagon, filtered_index_4_subindexes);
    const index_5 = calculateIndex(hexagon, filtered_index_5_subindexes);

    const indexes_length = [index_1, index_2, index_3, index_4, index_5].filter(
      (item) => item !== null
    ).length;

    const index_main =
      indexes_length !== 0
        ? (index_1 + index_2 + index_3 + index_4 + index_5) / indexes_length
        : null;

    const res = {
      ...hexagon,
      properties: {
        ...hexagon.properties,
        index_1,
        index_2,
        index_3,
        index_4,
        index_5,
        index_main,
      },
    };
    excludedIndexes.forEach((index) => {
      res.properties[index] = null;
    });
    return res;
  });
  // console.timeEnd('TIME:: 10 Hexagons mutation');

  // const testObj = zoom10Hexagons.map((item) => item.properties);
  // const test = calculateIndexesByMedian(testObj);
  //
  // debugger;

  return {
    data: result,
  };
};

export const calculateByMedian = (
  mode,
  hexagons,
  hexagons_10,
  excludedIndexes,
  activeFilters
) => {
  // FIXME Remove timeStamps
  if (mode === 'zoom_9') {
    console.time('TIME:: 9 Hexagon mutation');

    const result = hexagons
      .map((hexagon) => {
        const zoom10_ids =
          zoom8_9_10_connections.zoom9_id[hexagon.properties.hex_id];
        // console.log('hexagon', hexagon);
        // console.log('zoom10_ids', zoom10_ids);
        const zoom10_hexagons = zoom10_ids.map((id) => hexagons_10[id]);

        if (zoom10_hexagons.every((item) => !item)) {
          return undefined;
        }

        const indexes_by_median = calculateIndexesByMedian(
          zoom10_hexagons,
          excludedIndexes,
          activeFilters
        );

        const { index_main } = indexes_by_median;

        return {
          ...hexagon,
          properties: {
            ...hexagon.properties,
            ...indexes_by_median,
          },
        };
      })
      .filter((item) => item !== undefined);

    console.timeEnd('TIME:: 9 Hexagon mutation');

    return {
      data: result,
    };
  }
  if (mode === 'zoom_8') {
    console.time('TIME:: 8 Hexagons mutation');

    const result = hexagons
      .map((hexagon) => {
        const zoom9_ids =
          zoom8_9_10_connections.zoom8_id[hexagon.properties.hex_id];
        const zoom10_ids = [];
        zoom9_ids.forEach((id) => {
          zoom10_ids.push(...zoom8_9_10_connections.zoom9_id[id]);
        });
        const zoom10_hexagons = zoom10_ids.map((id) => hexagons_10[id]);

        if (zoom10_hexagons.every((item) => !item)) {
          return undefined;
        }

        const indexes_by_median = calculateIndexesByMedian(
          zoom10_hexagons,
          excludedIndexes,
          activeFilters
        );

        const { index_main } = indexes_by_median;

        return {
          ...hexagon,
          properties: {
            ...hexagon.properties,
            ...indexes_by_median,
          },
        };
      })
      .filter((item) => item !== undefined);

    console.timeEnd('TIME:: 8 Hexagons mutation');

    return {
      data: result,
    };
  }
};

export const formMutatedDict10 = (hexagons) => {
  const dict = {};

  hexagons.forEach((item) => {
    dict[item.properties.hex_id] = item.properties;
  });

  return dict;
};

export const formDynamicBuckets = (buckets, colors) => {
  const formatedBuckets = [];
  buckets.forEach((item, index) => {
    if (index === 0) return;
    if (index === 1) {
      formatedBuckets.push(['<', ['get', 'index_main'], item]);
    } else {
      let value = item;
      if (index === buckets.length - 1) {
        value = item + 1;
      }
      formatedBuckets.push([
        'all',
        ['>=', ['get', 'index_main'], buckets[index - 1]],
        ['<', ['get', 'index_main'], value],
      ]);
    }
  });

  const result = [
    'case',
    ['all', ['==', ['get', 'index_main'], null]],
    'rgba(160,160,160,0.3)',
  ];

  formatedBuckets.forEach((item, index) => {
    result.push(item, colors[index]);
  });
  result.push('rgba(0,0,0,0.7)');
  return result;
};

export const calculateHexagonsByMetrics = (startData, activeFilters) => {
  const { chosen_metrics } = activeFilters;

  // FIXME Part to rewrite
  const sub_index = chosen_metrics[0].slice(0, 7);

  return startData.map((item) => {
    const calculated_index =
      chosen_metrics.reduce((a, b) => a + item.properties[b], 0) /
      chosen_metrics.length;

    const index_main = calculated_index === 0 ? null : calculated_index;
    // if (
    //   !item.properties.is_urban &&
    //   non_urban_metrics.includes(chosen_metrics[0]) &&
    //   !item.properties[chosen_metrics[0]]
    // ) {
    //   index_main = null;
    // }

    return {
      ...item,
      properties: {
        ...item.properties,
        index_main,
        // FIXME Part to rewrite
        // [sub_index]: calculated_index,
      },
    };
  });
};

export const filterGradient = (hexagons, level) => {
  const gradientBlock = {
    '0-10': false,
    '10-20': false,
    '20-30': false,
    '30-40': false,
    '40-50': false,
    '50-60': false,
    '60-70': false,
    '70-80': false,
    '80-90': false,
    '90-100': false,
  };

  hexagons.forEach((hexagon) => {
    Object.keys(gradientBlock).forEach((bucket) => {
      const [min, max] = bucket.split('-').map((item) => Number(item));
      const { index_main } = hexagon.properties;
      if (index_main !== null) {
        if (gradientBlock[bucket]) return;
        if (index_main >= min && index_main < max) gradientBlock[bucket] = true;
      }
    });
  });

  return {
    zoom: level,
    value: gradientBlock,
  };
};
