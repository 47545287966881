export const hasActiveFilters = (activeFilters) => {
  if (
    activeFilters.zoom10_hex.id !== '' ||
    activeFilters.zoom9_hex.id !== '' ||
    activeFilters.zoom8_hex.id !== '' ||
    activeFilters.district.length > 0 ||
    Object.keys(activeFilters.draw_polygon).length > 0 ||
    activeFilters.gradient.length > 0
  ) {
    return true;
  }
  return false;
};
