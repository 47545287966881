import { createBrowserRouter } from 'react-router-dom';
import App from '../App.jsx';
import MainPage from '../pages/MainPage/MainPage.jsx';
import Login from '../pages/Login/Login.jsx';
import Register from '../pages/Register/Register.jsx';
import MainPageRestyle from '../pages/MainPage/MainPageRestyle.jsx';
import UserList from '../pages/UserList/UserLsit.jsx';
import UserView from '../pages/UserView/UserView.jsx';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      // { path: '/main-page', element: <MainPage /> },
      { path: '/root', element: <MainPageRestyle /> },
      { path: '/user-list', element: <UserList /> },
      { path: '/user/:id', element: <UserView /> },
      // { path: '/user', element: <div>TEST</div> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
]);

export default routes;
