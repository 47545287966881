import Icon from '@ant-design/icons';

function QalaAISvg() {
  return (
    <svg
      width="73"
      height="20"
      viewBox="0 0 73 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1368_18788)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00366211 0.00390625H20.1544V20.031H0.00366211V0.00390625ZM1.08413 1.07774V18.9571H19.0739V1.07774H1.08413Z"
          fill="currentColor"
        />
        <path
          d="M20.0859 19.9989H17.1146V15.0413H17.0101C17.0101 15.0413 16.0557 16.5196 13.7183 16.5196C10.5345 16.5196 8.94263 13.7778 8.94263 10.7173C8.94263 7.6569 10.5345 4.91504 13.7183 4.91504C16.1602 4.91504 17.1146 6.70835 17.1146 6.70835H17.2226V5.23003H20.0895V19.9989H20.0859ZM14.5683 7.13072C12.7639 7.13072 11.9139 8.73432 11.9139 10.7173C11.9139 12.7004 12.7639 14.304 14.5683 14.304C16.3726 14.304 17.2226 12.7004 17.2226 10.7173C17.2226 8.73432 16.3726 7.13072 14.5683 7.13072Z"
          fill="currentColor"
        />
        <path
          d="M21.4653 8.60546C21.6345 5.79917 23.9071 4.91504 26.4534 4.91504C28.9997 4.91504 31.2291 5.8636 31.2291 8.71284V16.2011H28.2578V14.0928H28.1533C28.1533 14.0928 27.4727 16.5196 24.649 16.5196C22.6322 16.5196 21.1483 15.5926 21.1483 13.5666C21.1483 11.5406 22.8446 10.5921 24.6706 10.1267C26.5182 9.66498 28.2578 9.51464 28.2578 8.29047C28.2578 7.42424 27.6203 6.91953 26.4534 6.91953C25.1785 6.91953 24.4798 7.42782 24.4365 8.60904H21.4653V8.60546ZM25.924 11.5585C24.9696 11.7912 24.1628 12.2744 24.1628 13.248C24.1628 14.0928 24.7571 14.6189 25.7115 14.6189C27.7284 14.6189 28.2578 12.2995 28.2578 10.6744C27.768 11.1182 26.7703 11.3509 25.924 11.5585Z"
          fill="currentColor"
        />
        <path
          d="M40.8848 16.2015H32.5004V5.23047H35.4717V13.8784H40.8848V16.1979V16.2015Z"
          fill="currentColor"
        />
        <path
          d="M42.0481 8.60546C42.2174 5.79917 44.49 4.91504 47.0363 4.91504C49.5826 4.91504 51.812 5.8636 51.812 8.71284V16.2011H48.8407V14.0928H48.7362C48.7362 14.0928 48.0555 16.5196 45.2319 16.5196C43.215 16.5196 41.7312 15.5926 41.7312 13.5666C41.7312 11.5406 43.4275 10.5921 45.2535 10.1267C47.1011 9.66498 48.8407 9.51464 48.8407 8.29047C48.8407 7.42424 48.2032 6.91953 47.0363 6.91953C45.7613 6.91953 45.0626 7.42782 45.0194 8.60904H42.0481V8.60546ZM46.5069 11.5585C45.5525 11.7912 44.7457 12.2744 44.7457 13.248C44.7457 14.0928 45.34 14.6189 46.2944 14.6189C48.3112 14.6189 48.8407 12.2995 48.8407 10.6744C48.3509 11.1182 47.3532 11.3509 46.5069 11.5585Z"
          fill="currentColor"
        />
        <path
          d="M54.8913 13.0371C55.8241 13.0371 56.5876 13.796 56.5876 14.723C56.5876 15.6501 55.8241 16.4125 54.8913 16.4125C53.9585 16.4125 53.1949 15.6537 53.1949 14.723C53.1949 13.7924 53.9585 13.0371 54.8913 13.0371Z"
          fill="currentColor"
        />
        <path
          d="M58.0715 8.60546C58.2407 5.79917 60.5133 4.91504 63.0596 4.91504C65.6059 4.91504 67.8353 5.8636 67.8353 8.71284V16.2011H64.864V14.0928H64.7596C64.7596 14.0928 64.0789 16.5196 61.2552 16.5196C59.2384 16.5196 57.7545 15.5926 57.7545 13.5666C57.7545 11.5406 59.4509 10.5921 61.2768 10.1267C63.1244 9.66498 64.864 9.51464 64.864 8.29047C64.864 7.42424 64.2265 6.91953 63.0596 6.91953C61.7847 6.91953 61.086 7.42782 61.0427 8.60904H58.0715V8.60546ZM62.5302 11.5585C61.5758 11.7912 60.769 12.2744 60.769 13.248C60.769 14.0928 61.3633 14.6189 62.3177 14.6189C64.3346 14.6189 64.864 12.2995 64.864 10.6744C64.3742 11.1182 63.3766 11.3509 62.5302 11.5585Z"
          fill="currentColor"
        />
        <path
          d="M70.5942 1.11719C71.527 1.11719 72.2905 1.87603 72.2905 2.80669C72.2905 3.73735 71.527 4.49261 70.5942 4.49261C69.6614 4.49261 68.8978 3.73377 68.8978 2.80669C68.8978 1.87961 69.6614 1.11719 70.5942 1.11719ZM72.0816 16.201H69.1103V5.22998H72.0816V16.201Z"
          fill="currentColor"
        />
        <path
          d="M54.8914 13.0371C55.8242 13.0371 56.5877 13.796 56.5877 14.7266C56.5877 15.6573 55.8242 16.4125 54.8914 16.4125C53.9586 16.4125 53.1951 15.6537 53.1951 14.7266C53.1951 13.7995 53.9586 13.0371 54.8914 13.0371Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1368_18788">
          <rect width="73" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

function QalaAISvgMini() {
  return (
    <svg
      width="65"
      height="18"
      viewBox="0 0 73 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1368_18788)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.00366211 0.00390625H20.1544V20.031H0.00366211V0.00390625ZM1.08413 1.07774V18.9571H19.0739V1.07774H1.08413Z"
          fill="currentColor"
        />
        <path
          d="M20.0859 19.9989H17.1146V15.0413H17.0101C17.0101 15.0413 16.0557 16.5196 13.7183 16.5196C10.5345 16.5196 8.94263 13.7778 8.94263 10.7173C8.94263 7.6569 10.5345 4.91504 13.7183 4.91504C16.1602 4.91504 17.1146 6.70835 17.1146 6.70835H17.2226V5.23003H20.0895V19.9989H20.0859ZM14.5683 7.13072C12.7639 7.13072 11.9139 8.73432 11.9139 10.7173C11.9139 12.7004 12.7639 14.304 14.5683 14.304C16.3726 14.304 17.2226 12.7004 17.2226 10.7173C17.2226 8.73432 16.3726 7.13072 14.5683 7.13072Z"
          fill="currentColor"
        />
        <path
          d="M21.4653 8.60546C21.6345 5.79917 23.9071 4.91504 26.4534 4.91504C28.9997 4.91504 31.2291 5.8636 31.2291 8.71284V16.2011H28.2578V14.0928H28.1533C28.1533 14.0928 27.4727 16.5196 24.649 16.5196C22.6322 16.5196 21.1483 15.5926 21.1483 13.5666C21.1483 11.5406 22.8446 10.5921 24.6706 10.1267C26.5182 9.66498 28.2578 9.51464 28.2578 8.29047C28.2578 7.42424 27.6203 6.91953 26.4534 6.91953C25.1785 6.91953 24.4798 7.42782 24.4365 8.60904H21.4653V8.60546ZM25.924 11.5585C24.9696 11.7912 24.1628 12.2744 24.1628 13.248C24.1628 14.0928 24.7571 14.6189 25.7115 14.6189C27.7284 14.6189 28.2578 12.2995 28.2578 10.6744C27.768 11.1182 26.7703 11.3509 25.924 11.5585Z"
          fill="currentColor"
        />
        <path
          d="M40.8848 16.2015H32.5004V5.23047H35.4717V13.8784H40.8848V16.1979V16.2015Z"
          fill="currentColor"
        />
        <path
          d="M42.0481 8.60546C42.2174 5.79917 44.49 4.91504 47.0363 4.91504C49.5826 4.91504 51.812 5.8636 51.812 8.71284V16.2011H48.8407V14.0928H48.7362C48.7362 14.0928 48.0555 16.5196 45.2319 16.5196C43.215 16.5196 41.7312 15.5926 41.7312 13.5666C41.7312 11.5406 43.4275 10.5921 45.2535 10.1267C47.1011 9.66498 48.8407 9.51464 48.8407 8.29047C48.8407 7.42424 48.2032 6.91953 47.0363 6.91953C45.7613 6.91953 45.0626 7.42782 45.0194 8.60904H42.0481V8.60546ZM46.5069 11.5585C45.5525 11.7912 44.7457 12.2744 44.7457 13.248C44.7457 14.0928 45.34 14.6189 46.2944 14.6189C48.3112 14.6189 48.8407 12.2995 48.8407 10.6744C48.3509 11.1182 47.3532 11.3509 46.5069 11.5585Z"
          fill="currentColor"
        />
        <path
          d="M54.8913 13.0371C55.8241 13.0371 56.5876 13.796 56.5876 14.723C56.5876 15.6501 55.8241 16.4125 54.8913 16.4125C53.9585 16.4125 53.1949 15.6537 53.1949 14.723C53.1949 13.7924 53.9585 13.0371 54.8913 13.0371Z"
          fill="currentColor"
        />
        <path
          d="M58.0715 8.60546C58.2407 5.79917 60.5133 4.91504 63.0596 4.91504C65.6059 4.91504 67.8353 5.8636 67.8353 8.71284V16.2011H64.864V14.0928H64.7596C64.7596 14.0928 64.0789 16.5196 61.2552 16.5196C59.2384 16.5196 57.7545 15.5926 57.7545 13.5666C57.7545 11.5406 59.4509 10.5921 61.2768 10.1267C63.1244 9.66498 64.864 9.51464 64.864 8.29047C64.864 7.42424 64.2265 6.91953 63.0596 6.91953C61.7847 6.91953 61.086 7.42782 61.0427 8.60904H58.0715V8.60546ZM62.5302 11.5585C61.5758 11.7912 60.769 12.2744 60.769 13.248C60.769 14.0928 61.3633 14.6189 62.3177 14.6189C64.3346 14.6189 64.864 12.2995 64.864 10.6744C64.3742 11.1182 63.3766 11.3509 62.5302 11.5585Z"
          fill="currentColor"
        />
        <path
          d="M70.5942 1.11719C71.527 1.11719 72.2905 1.87603 72.2905 2.80669C72.2905 3.73735 71.527 4.49261 70.5942 4.49261C69.6614 4.49261 68.8978 3.73377 68.8978 2.80669C68.8978 1.87961 69.6614 1.11719 70.5942 1.11719ZM72.0816 16.201H69.1103V5.22998H72.0816V16.201Z"
          fill="currentColor"
        />
        <path
          d="M54.8914 13.0371C55.8242 13.0371 56.5877 13.796 56.5877 14.7266C56.5877 15.6573 55.8242 16.4125 54.8914 16.4125C53.9586 16.4125 53.1951 15.6537 53.1951 14.7266C53.1951 13.7995 53.9586 13.0371 54.8914 13.0371Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_1368_18788">
          <rect width="73" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default function QalaAI() {
  return <Icon component={QalaAISvg} />;
}

export function QalaAIMini() {
  return <Icon component={QalaAISvgMini} />;
}
