export const ru_en_page_dictionary = {
  resilience_index: {
    ru: 'Индекс жизнестойкости',
    en: 'Resilience index',
  },
  filters: {
    ru: 'Фильтры',
    en: 'Filters',
  },
  params: {
    ru: 'Параметры',
    en: 'Parameters',
  },
  submit_button: {
    ru: 'Применить',
    en: 'Apply',
  },
  reset_button: {
    ru: 'Сбросить все',
    en: 'Reset all',
  },
  quarter_placeholder: {
    ru: 'Квартал',
    en: 'Quarter',
  },
  districts_placeholder: {
    ru: 'Выберите район',
    en: 'Choose district',
  },
  geocoder_placeholder: {
    ru: 'Введите адрес',
    en: 'Enter address',
  },
  select_all: {
    ru: 'Выбрать все',
    en: 'Select all',
  },
  isochrone_title: {
    ru: 'Зона доступности',
    en: 'Availability zone',
  },
  isochrone_segmented_1: {
    ru: 'Пешеход',
    en: 'Pedestrian',
  },
  isochrone_segmented_2: {
    ru: 'Авто',
    en: 'Auto',
  },
  isochrone_time_measure: {
    ru: 'мин',
    en: 'min',
  },
  fav_metrics: {
    ru: 'Избранные',
    en: 'Favorites',
  },
  no_fav_metrics: {
    ru: 'Нет избранных',
    en: 'No Favorites',
  },
  radar_tooltip: {
    ru: 'Индекс',
    en: 'Index',
  },
  polygon_button: {
    ru: 'Выделить область',
    en: 'Draw region',
  },
  delete_button: {
    ru: 'Удалить область',
    en: 'Delete region',
  },
  isochrone_button: {
    ru: 'Зона доступности',
    en: 'Availability zone',
  },
  zoom_in: {
    ru: 'Приблизить',
    en: 'Zoom in',
  },
  zoom_out: {
    ru: 'Отдалить',
    en: 'Zoom out',
  },
  zoom_home: {
    ru: 'Вернуться',
    en: 'Reset',
  },
  table_name: {
    ru: 'Имя',
    en: 'Name',
  },
  table_email: {
    ru: 'E-mail',
    en: 'E-mail',
  },
  table_organization: {
    ru: 'Организация',
    en: 'Organization',
  },
  table_status: {
    ru: 'Статус',
    en: 'Status',
  },
  table_action: {
    ru: 'Дайствия',
    en: 'Actions',
  },
  table_action_unblock: {
    ru: 'Разблокировать пользователя',
    en: 'Unblock user',
  },
  table_action_block: {
    ru: 'Заблокировать пользователя',
    en: 'Block user',
  },
  table_action_confirm: {
    ru: 'Подтвердить пользователя',
    en: 'Confirm user',
  },
  table_performance: {
    ru: 'Производительность',
    en: 'Performance',
  },
  table_download: {
    ru: 'Загрузка',
    en: 'Download',
  },
  table_mutate: {
    ru: 'Мутирование',
    en: 'Mutate',
  },
  table_to_front: {
    ru: 'фронт',
    en: 'front',
  },
  table_to_back: {
    ru: 'бэк',
    en: 'back',
  },
  table_switch: {
    ru: 'Переключить просчет на',
    en: 'Switch calculation to',
  },
  table_action_view: {
    ru: 'Просмотреть пользователя',
    en: 'View user',
  },
  status_blocked: {
    ru: 'Заблокирован',
    en: 'Blocked',
  },
  status_confirmed: {
    ru: 'Подтвержден',
    en: 'Confirmed',
  },
  status_undefined: {
    ru: 'Неопределен',
    en: 'Undefined',
  },
  user: {
    ru: 'Пользователь',
    en: 'User',
  },
  chart_download: {
    ru: 'Статистика скорости загрузки',
    en: 'Download speed stats',
  },
  chart_mutation: {
    ru: 'Статистика скорости мутации',
    en: 'Mutation speed stats',
  },
  user_table_date: {
    ru: 'Дата',
    en: 'Date',
  },
  user_table_download: {
    ru: 'Скорость загрузки',
    en: 'Download speed',
  },
  user_table_mutation: {
    ru: 'Скорость мутации',
    en: 'Mutation speed',
  },
  table_admin: {
    ru: 'Админ',
    en: 'Admin',
  },
  make_admin: {
    ru: 'Назначить админом',
    en: 'Make admin',
  },
  table_timers: {
    ru: 'Таймеры',
    en: 'Timers',
  },
  side_description: {
    ru: 'Описание',
    en: 'Description',
  },
  side_calculation: {
    ru: 'Метод расчета',
    en: 'Calculation method',
  },
  side_hist_data: {
    ru: 'Исторические данные',
    en: 'Historical data',
  },
  isochrone_traffic: {
    ru: 'Трафик',
    en: 'Traffic',
  },
  map_style_streets: {
    ru: 'Рельеф',
    en: 'Streets',
  },
  map_style_voyager: {
    ru: 'Улицы',
    en: 'Voyager',
  },
  map_style_dark: {
    ru: 'Темная',
    en: 'Dark',
  },
  map_style_light: {
    ru: 'Светлая',
    en: 'Light',
  },
  map_style_satellite: {
    ru: 'Снимок',
    en: 'Satellite',
  },
  report_button: {
    ru: 'Сформировать отчет',
    en: 'Generate report',
  },
  report_title: {
    ru: 'Отчеты',
    en: 'Reports',
  },
  report_new: {
    ru: 'Формирование нового отчета',
    en: 'Generate new report',
  },
  report_new_tablet: {
    ru: 'Новый отчет',
    en: 'New report',
  },
  report_archive: {
    ru: 'Архив отчетов',
    en: 'Reports archive',
  },
  report_name: {
    ru: 'Название файла',
    en: 'File name',
  },
  report_checkbox_1: {
    ru: 'Использовать примененные к показателям RCP фильтры',
    en: 'Use filters applied to RCP metrics',
  },
  report_checkbox_2: {
    ru: 'Использовать примененные пространственные фильтры (выделенная территория, зона доступности)',
    en: 'Use applied spatial filters (selected area, accessibility zone)',
  },
  report_checkbox_3: {
    ru: 'Учитывать разделение по районам',
    en: 'Take into account the division by region',
  },
  report_name_error: {
    ru: 'Неверное имя файла. Имя файла не может быть пустым и не может содержать / , : ; * ? " < > | ~ \\ | ;',
    en: 'Invalid file name. File name cannot  be empty and cannot contain / , : ; * ? " < > | ~ \\ | ;',
  },
  user_list_title: {
    ru: 'Список пользователей',
    en: 'User list',
  },
  profile_user_list: {
    ru: 'Список пользователей',
    en: 'User list',
  },
  profile_logout: {
    ru: 'Выход',
    en: 'Logout',
  },
  algorithm: {
    ru: 'Алгоритм',
    en: 'Algorithm',
  },
  gradient_by: {
    ru: 'Градиент по',
    en: 'Gradient by',
  },
  isochrone_custom_time: {
    ru: 'Введите время (мин)',
    en: 'Enter time (min)',
  },
  metrics: {
    ru: 'Показатели',
    en: 'Metrics',
  },
  index: {
    ru: 'Индекс',
    en: 'Index',
  },
  reports: {
    ru: 'Отчеты',
    en: 'Reports',
  },
  theme: {
    ru: 'Режим',
    en: 'Theme',
  },
  language: {
    ru: 'Язык',
    en: 'Language',
  },
};
