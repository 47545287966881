import { createStore } from 'effector';

const initialState = [];

export const $isochroneStore = createStore(initialState);
$isochroneStore.watch((state) => console.log('$isochroneStore', state));

export const $isochroneTime = createStore([0]);

export const $isTraffic = createStore(false);

export const $isochroneType = createStore('walk');

export const $isochroneCustomTime = createStore(null);
