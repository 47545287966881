import { sample } from 'effector';
import {
  clearIsochroneEv,
  putIsochroneDataEv,
  getIsochroneByPointEv,
  changeIsochroneTimeEv,
  getIsochroneByTimeEv,
  changeTrafficEv,
  changeIsochroneTypeEv,
  changeIsochroneCustomTimeEv,
} from './events.js';
import {
  $isochroneCustomTime,
  $isochroneStore,
  $isochroneTime,
  $isochroneType,
  $isTraffic,
} from './stores.js';
import { getIsochroneFx } from './effects.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import { timeValues } from '../../dictionaries/isochrone_dict.js';
import { changeDrawModeEv } from '../mapModel/index.js';
import { showLoaderEv } from '../webSocketModel/index.js';

$isochroneStore.on(clearIsochroneEv, (state, payload) => {
  window.draw.changeMode('simple_select');
  window.draw.set({
    type: 'FeatureCollection',
    features: window.draw
      .getAll()
      .features.filter((item) => item.geometry.type !== 'Point'),
  });

  // changeLayerVisibility('zoom8_hexagon_layer', 'visible');
  // changeLayerVisibility('zoom9_hexagon_layer', 'visible');
  // changeLayerVisibility('zoom10_hexagon_layer', 'visible');

  return [];
});

$isochroneTime.reset(clearIsochroneEv);
$isochroneCustomTime.reset(clearIsochroneEv);

sample({
  clock: putIsochroneDataEv,
  fn: (clock) => {
    // changeLayerVisibility('zoom8_hexagon_layer', 'none');
    // changeLayerVisibility('zoom9_hexagon_layer', 'none');
    // changeLayerVisibility('zoom10_hexagon_layer', 'none');

    return clock.feature.map((item) => {
      return {
        ...item,
        geometry: {
          ...item.geometry,
          type: 'Polygon',
          coordinates: [item.geometry.coordinates],
        },
      };
    });
  },
  target: $isochroneStore,
});

sample({
  clock: changeIsochroneTimeEv,
  fn: (clock) => [clock],
  target: $isochroneTime,
});

sample({
  clock: changeIsochroneCustomTimeEv,
  fn: (clock) => clock,
  target: $isochroneCustomTime,
});

sample({
  source: [$isochroneTime, $isTraffic, $isochroneType],
  clock: getIsochroneByPointEv,
  fn: ([time, isTraffic, type], clock) => {
    return {
      coordinates: clock.geometry.coordinates,
      time: timeValues[time[0]],
      traffic: isTraffic,
      type,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});

sample({
  source: [$isTraffic, $isochroneType],
  clock: getIsochroneByTimeEv,
  filter: () => {
    const point = window.draw
      .getAll()
      .features.filter((item) => item.geometry.type === 'Point');

    return point.length > 0 && point[0].geometry.coordinates.length > 0;
  },
  fn: ([isTraffic, type], clock) => {
    return {
      coordinates: window.draw
        .getAll()
        .features.filter((item) => item.geometry.type === 'Point')[0].geometry
        .coordinates,
      time: timeValues[clock],
      traffic: isTraffic,
      type,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});

sample({
  clock: clearIsochroneEv,
  fn: () => 'simple_select',
  target: changeDrawModeEv,
});

sample({
  clock: changeTrafficEv,
  fn: (clock) => clock,
  target: $isTraffic,
});

sample({
  source: [$isochroneTime, $isochroneType],
  clock: $isTraffic.updates,
  filter: () => {
    const point = window.draw
      .getAll()
      .features.filter((item) => item.geometry.type === 'Point');

    return point.length > 0 && point[0].geometry.coordinates.length > 0;
  },
  fn: ([time, type], clock) => {
    return {
      coordinates: window.draw
        .getAll()
        .features.filter((item) => item.geometry.type === 'Point')[0].geometry
        .coordinates,
      time: timeValues[time[0]],
      traffic: clock,
      type,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});

sample({
  clock: changeIsochroneTypeEv,
  fn: (clock) => clock,
  target: $isochroneType,
});

sample({
  source: [$isochroneTime, $isTraffic],
  clock: $isochroneType.updates,
  filter: () => {
    const point = window.draw
      .getAll()
      .features.filter((item) => item.geometry.type === 'Point');

    return point.length > 0 && point[0].geometry.coordinates.length > 0;
  },
  fn: ([time, isTraffic], clock) => {
    return {
      coordinates: window.draw
        .getAll()
        .features.filter((item) => item.geometry.type === 'Point')[0].geometry
        .coordinates,
      time: timeValues[time[0]],
      traffic: isTraffic,
      type: clock,
    };
  },
  target: [getIsochroneFx, showLoaderEv],
});
