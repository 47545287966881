import {
  $drawMode,
  $interactivity,
  $layersOpacity,
  $mapLoaded,
  $searchOptions,
} from './stores.js';
import {
  changeDrawModeEv,
  changeInteractivityEv,
  changeLayersOpacityEv,
  putSearchOptionsEv,
  resetMapLoadedEv,
  setMapLoadedEv,
} from './events.js';

$mapLoaded.on(setMapLoadedEv, (_, payload) => payload).reset(resetMapLoadedEv);

$interactivity.on(changeInteractivityEv, (_, payload) => payload);

$searchOptions.on(putSearchOptionsEv, (_, payload) => payload);

$drawMode.on(changeDrawModeEv, (_, payload) => payload);

$layersOpacity.on(changeLayersOpacityEv, (_, payload) => payload);
