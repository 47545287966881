import { sample } from 'effector';
import {
  changeModalOpenEv,
  putChartDataEv,
  putCityIndexEv,
  putFilteredChartDataEv,
  putFilteredIndexCardsDataEv,
  putIndexCardsDataEv,
  saveHexDataEv,
  updateCardsByZoom10Ev,
  updateChartByZoom10Ev,
} from './events.js';
import {
  $chosenMetric,
  $cityIndex,
  $hexagonsData,
  $indexCards,
  $indexCardsStart,
  $indexChartData,
  $indexChartStartData,
  $indexTree,
  $modalOpen,
} from './stores.js';
import {
  formatCardsWidgetData,
  formatChartWidgetData,
  formCardsFromFeature,
  formCardsFromZoom10,
  formChartFromFeature,
  formChartFromZoom10,
} from '../../utils/index-widgets-utils.js';
import {
  $zoom10Hexagons,
  $zoom10MutatedHexagons,
  $zoom10StartHexagons,
} from '../zoom10Model/index.js';
import { $language } from '../authModel/index.js';
import index_names_dynamics from '../../dictionaries/index_names_dymanics.json';
import {
  $activeFilters,
  selectAllEv,
  submitFiltersEv,
} from '../activeFiltersModel/index.js';
import { hasActiveFilters } from '../../utils/active-filters-utils.js';
import { $isochroneStore } from '../isochroneModel/index.js';

// $cityIndex.on(putCityIndexEv, (state, payload) => payload);

$modalOpen.on(changeModalOpenEv, (state, payload) => !!payload);

$chosenMetric.on(changeModalOpenEv, (state, payload) => {
  if (!payload) {
    return 0;
  }
  return payload;
});

sample({
  source: $language,
  clock: putIndexCardsDataEv,
  fn: (source, clock) => formatCardsWidgetData(clock, source),
  target: [$indexCards, $indexCardsStart],
});

sample({
  source: $language,
  clock: putFilteredIndexCardsDataEv,
  fn: (source, clock) => formatCardsWidgetData(clock, source),
  target: $indexCards,
});

sample({
  source: $language,
  clock: putChartDataEv,
  fn: (source, clock) => formatChartWidgetData(clock, source),
  target: [$indexChartData, $indexChartStartData],
});

sample({
  source: $language,
  clock: putFilteredChartDataEv,
  fn: (source, clock) => formatChartWidgetData(clock, source),
  target: $indexChartData,
});

sample({
  source: $hexagonsData,
  clock: saveHexDataEv,
  fn: (source, clock) => {
    if (source[clock.level].id === clock.data.id) {
      return {
        ...source,
        [clock.level]: {
          id: '',
          data: {},
        },
      };
    }
    return {
      ...source,
      [clock.level]: {
        id: clock.data.id,
        data: clock.data.indexes,
      },
    };
  },
  target: $hexagonsData,
});

// sample({
//   source: [$indexCardsStart, $language],
//   clock: $hexagonsData.updates,
//   fn: ([source, language], clock) => {
//     if (clock.zoom_10.id.length > 0) {
//       return formCardsFromFeature(clock.zoom_10.data, language);
//     }
//     if (clock.zoom_9.id.length > 0) {
//       return formCardsFromFeature(clock.zoom_9.data, language);
//     }
//     if (clock.zoom_8.id.length > 0) {
//       return formCardsFromFeature(clock.zoom_8.data, language);
//     }
//     return source;
//   },
//   target: $indexCards,
// });

// FIXME Logic to filter RadarChart
// sample({
//   source: [$indexChartStartData, $language],
//   clock: $hexagonsData.updates,
//   fn: ([source, language], clock) => {
//     if (clock.zoom_10.id.length > 0) {
//       return formChartFromFeature(clock.zoom_10.data, language);
//     }
//     if (clock.zoom_9.id.length > 0) {
//       return formChartFromFeature(clock.zoom_9.data, language);
//     }
//     if (clock.zoom_8.id.length > 0) {
//       return formChartFromFeature(clock.zoom_8.data, language);
//     }
//     return source;
//   },
//   target: $indexChartData,
// });

// FIXME Old calculation by 10 update
// sample({
//   source: [$language, $activeFilters, $isochroneStore],
//   clock: $zoom10Hexagons.updates,
//   filter: ([language, activeFilters, isohroneStore], clock) =>
//     hasActiveFilters(activeFilters) || isohroneStore.length > 0,
//   fn: ([language, activeFilters, isohroneStore], clock) => {
//     return formChartFromZoom10(clock, language);
//   },
//   target: $indexChartData,
// });

sample({
  source: [$language, $indexChartStartData, $hexagonsData],
  clock: updateChartByZoom10Ev,
  fn: ([language, chartData, hexagonsData], clock) => {
    const payload = {
      sub_index: {},
    };

    let data = chartData;

    if (hexagonsData.zoom_10.id !== '') {
      data = formChartFromFeature(hexagonsData.zoom_10.data, language);
    } else if (hexagonsData.zoom_9.id !== '') {
      data = formChartFromFeature(hexagonsData.zoom_9.data, language);
    } else if (hexagonsData.zoom_8.id !== '') {
      data = formChartFromFeature(hexagonsData.zoom_8.data, language);
    }

    const indexes_to_change = [];

    Object.keys(clock)
      .filter((key) => /index_\d{1}$/g.test(key))
      .forEach((key) => {
        indexes_to_change.push(key);
        const index = key.split('_')[1];
        payload.sub_index[index] = clock[key];
      });

    const new_data = formatChartWidgetData(payload, language);

    return [
      ...data.filter((item) => !indexes_to_change.includes(item.index_name)),
      ...new_data,
    ].sort((a, b) => a.index_name.localeCompare(b.index_name));
  },
  target: $indexChartData,
});

sample({
  source: [
    $indexChartStartData,
    $isochroneStore,
    $activeFilters,
    $zoom10MutatedHexagons,
  ],
  clock: [
    $activeFilters.updates,
    $isochroneStore.updates,
    $zoom10MutatedHexagons.updates,
  ],
  filter: ([source, isochroneStore, activeFilters, zoom10Hex], clock) =>
    !hasActiveFilters(activeFilters) &&
    zoom10Hex.length === 0 &&
    activeFilters.excludedIndexes.length === 0 &&
    isochroneStore.length === 0,
  fn: ([source, isochroneStore, activeFilters], clock) => source,
  target: $indexChartData,
});

// FIXME Old calculation by 10 update
// sample({
//   source: [$language, $activeFilters, $isochroneStore],
//   clock: $zoom10Hexagons.updates,
//   filter: ([language, activeFilters, isohroneStore], clock) =>
//     hasActiveFilters(activeFilters) || isohroneStore.length > 0,
//   fn: ([language, activeFilters], clock) =>
//     formCardsFromZoom10(clock, language),
//   target: $indexCards,
// });

sample({
  source: [$language, $indexCardsStart, $hexagonsData],
  clock: updateCardsByZoom10Ev,
  fn: ([language, indexCards, hexagonsData], clock) => {
    const payload = {
      sub_index: {},
      metrics: {},
    };

    let data = indexCards;
    if (hexagonsData.zoom_10.id !== '') {
      data = formCardsFromFeature(hexagonsData.zoom_10.data, language);
    } else if (hexagonsData.zoom_9.id !== '') {
      data = formCardsFromFeature(hexagonsData.zoom_9.data, language);
    } else if (hexagonsData.zoom_8.id !== '') {
      data = formCardsFromFeature(hexagonsData.zoom_8.data, language);
    }

    const index_to_change = [];
    Object.keys(clock).forEach((prop) => {
      const index = prop.split('index_')[1];
      if (/index_\d{3}/g.test(prop)) {
        payload.metrics[index] = clock[prop];
      } else if (/index_\d{1}/g.test(prop)) {
        index_to_change.push(prop);
        payload.sub_index[index] = clock[prop];
      }
    });

    const new_data = formatCardsWidgetData(payload, language);

    return [
      ...data.filter((card) => !index_to_change.includes(card.index_name)),
      ...new_data,
    ]
      .filter((card) => card.children.length !== 0)
      .sort((a, b) => a.index_name.localeCompare(b.index_name));
  },
  target: $indexCards,
});

sample({
  source: [
    $indexCardsStart,
    $isochroneStore,
    $activeFilters,
    $zoom10MutatedHexagons,
  ],
  clock: [
    $activeFilters.updates,
    $isochroneStore.updates,
    $zoom10MutatedHexagons.updates,
  ],
  filter: ([source, isochroneStore, activeFilters, zoom10Hex], clock) =>
    !hasActiveFilters(activeFilters) &&
    zoom10Hex.length === 0 &&
    activeFilters.excludedIndexes.length === 0 &&
    isochroneStore.length === 0,
  fn: ([source, isochroneStore, activeFilters], clock) => source,
  target: $indexCards,
});

sample({
  source: $indexTree,
  clock: $language.updates,
  fn: (source, clock) => {
    return source.map((item) => {
      return {
        ...item,
        title:
          index_names_dynamics[item.key.split('index_')[1]][`name_${clock}`],
        children: item.children.map((child) => {
          return {
            ...child,
            title:
              index_names_dynamics[child.key.split('index_')[1]][
                `name_${clock}`
              ],
          };
        }),
      };
    });
  },
  target: $indexTree,
});

sample({
  source: $indexCards,
  clock: $language.updates,
  fn: (source, language) => {
    return source.map((item) => {
      return {
        ...item,
        title:
          index_names_dynamics[item.index_name.split('index_')[1]][
            `name_${language}`
          ],
        children: item.children.map((child) => {
          const index = child.index_name.split('index_')[1];
          return {
            ...child,
            title: index_names_dynamics[index][`name_${language}`],
          };
        }),
      };
    });
  },
  target: $indexCards,
});

sample({
  source: $indexChartData,
  clock: $language.updates,
  fn: (source, language) => {
    return source.map((item) => {
      let langFilter = '[а-я]';
      if (language === 'en') {
        langFilter = '[a-z]{3}';
      }

      const regexp = new RegExp(`( )${langFilter}[^ ]`, 'gi');

      return {
        ...item,
        title: index_names_dynamics[item.index_name.split('index_')[1]][
          `name_${language}`
        ].replaceAll(regexp, (match) => `\n${match.trim()}`),
      };
    });
  },
  target: $indexChartData,
});

sample({
  source: $indexCardsStart,
  clock: $language.updates,
  fn: (source, language) => {
    return source.map((item) => {
      return {
        ...item,
        title:
          index_names_dynamics[item.index_name.split('index_')[1]][
            `name_${language}`
          ],
        children: item.children.map((child) => {
          const index = child.index_name.split('index_')[1];
          return {
            ...child,
            title: index_names_dynamics[index][`name_${language}`],
          };
        }),
      };
    });
  },
  target: $indexCardsStart,
});

sample({
  source: $indexChartStartData,
  clock: $language.updates,
  fn: (source, language) => {
    return source.map((item) => {
      let langFilter = '[а-я]';
      if (language === 'en') {
        langFilter = '[a-z]{3}';
      }

      const regexp = new RegExp(`( )${langFilter}[^ ]`, 'gi');

      return {
        ...item,
        title: index_names_dynamics[item.index_name.split('index_')[1]][
          `name_${language}`
        ].replaceAll(regexp, (match) => `\n${match.trim()}`),
      };
    });
  },
  target: $indexChartStartData,
});

sample({
  source: [$indexCardsStart, $activeFilters],
  clock: [submitFiltersEv, selectAllEv],
  fn: ([startData, activeFilters], clock) => {
    if (activeFilters.excludedIndexes.length > 0) {
      const remove1 =
        activeFilters.excludedIndexes.filter((item) =>
          /index_1\d{2}/g.test(item)
        ).length === 16;
      const remove2 =
        activeFilters.excludedIndexes.filter((item) =>
          /index_2\d{2}/g.test(item)
        ).length === 19;
      const remove3 =
        activeFilters.excludedIndexes.filter((item) =>
          /index_3\d{2}/g.test(item)
        ).length === 11;
      const remove4 =
        activeFilters.excludedIndexes.filter((item) =>
          /index_4\d{2}/g.test(item)
        ).length === 11;
      const remove5 =
        activeFilters.excludedIndexes.filter((item) =>
          /index_5\d{2}/g.test(item)
        ).length === 15;

      const indexesToRemove = [];
      [remove1, remove2, remove3, remove4, remove5].forEach((remove, index) => {
        if (remove) indexesToRemove.push(`index_${index + 1}`);
      });

      let filteredData = startData;
      if (indexesToRemove.length > 0) {
        filteredData = filteredData.filter(
          (item) => !indexesToRemove.includes(item.index_name)
        );
      }
      filteredData = filteredData.map((item) => {
        return {
          ...item,
          children: item.children.filter(
            (child) => !activeFilters.excludedIndexes.includes(child.index_name)
          ),
        };
      });
      return filteredData;
    }
    return startData;
  },
  target: $indexCards,
});
