import { Divider } from 'antd';
import { useUnit } from 'effector-react';
import { useEffect, useState } from 'react';
import RadarChartNew from '../RadarChart/RadarChartNew.jsx';
import style from './LeftBar.module.css';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $isDarkTheme, $language } from '../../models/authModel/index.js';
import { $indexChartData } from '../../models/indexCardsModel/index.js';

export default function TabletRadar() {
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);
  const indexChartData = useUnit($indexChartData);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const filteredChartData = indexChartData.filter((item) => item.value !== 0);
    setIndex(
      filteredChartData.reduce((sum, item) => sum + +item.value, 0) /
        filteredChartData.length || 0
    );
  }, [indexChartData]);
  return (
    <div className={style.tablet_radar_wrapper}>
      {/* <div */}
      {/*  className={style.radar_title} */}
      {/*  style={{ color: darkMode ? 'white' : 'black' }} */}
      {/* > */}
      {/*  {ru_en_page_dictionary.resilience_index[language]}: {index.toFixed(2)} / */}
      {/*  100 */}
      {/* </div> */}
      <RadarChartNew showInfo={false} />
      <div className={style.tooltip_wrapper}>
        {indexChartData.map((item) => {
          const indexVal = item.index_name.split('index_')[1];
          return (
            <div
              key={`tooltip-${item.index_name}`}
              className={style.tooltip_item}
            >
              <div className={style.tooltip_icon}>{`i${indexVal}`}</div>
              {item.title} - {item.value}
            </div>
          );
        })}
      </div>
    </div>
  );
}
