import { Card, Divider, Dropdown, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useUnit } from 'effector-react';
import Icon, { AppstoreOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { QalaAIMini } from '../ContentContainter/QalaAI.jsx';
import {
  $isDarkTheme,
  $language,
  $userInfo,
  changeDarkThemeEv,
  changeLanguageEv,
  logoutEv,
} from '../../models/authModel/index.js';
import style from './TabletLeftBar.module.css';
import { $activeFilters } from '../../models/activeFiltersModel/index.js';
import { metrics } from '../../data/metrics.js';
import { tablet_icons } from '../../dictionaries/icons_dict.jsx';
import { $indexChartData } from '../../models/indexCardsModel/index.js';
import { wsSendDarkTheme } from '../../utils/webSocketConfig.js';
import { setMapLoadedEv } from '../../models/mapModel/index.js';
import TabletContentManager from './TabletContentManager.jsx';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';

const { Title } = Typography;

export default function TabletLeftBar() {
  const darkMode = useUnit($isDarkTheme);
  const { excludedIndexes } = useUnit($activeFilters);
  const indexChartData = useUnit($indexChartData);
  const [activeTab, setActiveTab] = useState(0);
  const userInfo = useUnit($userInfo);
  const changeDarkTheme = useUnit(changeDarkThemeEv);
  const language = useUnit($language);
  const changeLanguage = useUnit(changeLanguageEv);
  const logout = useUnit(logoutEv);
  const navigate = useNavigate();

  const [index, setIndex] = useState(0);

  const toggleTheme = () => {
    wsSendDarkTheme(!darkMode);
    setMapLoadedEv(false);

    const link = !darkMode
      ? 'mapbox://styles/mapbox/dark-v11'
      : 'mapbox://styles/mapbox/streets-v12';
    if (window.map) {
      window.map.setStyle(link);
    }
    changeDarkTheme();
  };

  useEffect(() => {
    const filteredChartData = indexChartData.filter((item) => item.value !== 0);
    setIndex(
      filteredChartData.reduce((sum, item) => sum + +item.value, 0) /
        filteredChartData.length || 0
    );
  }, [indexChartData]);

  const tabs_items = [
    {
      key: 0,
      label: (
        <>
          <AppstoreOutlined className={style.tab_icon} />
          <div className={style.tab_label}>
            {ru_en_page_dictionary.metrics[language]}
          </div>
        </>
      ),
    },
    {
      key: 1,
      label: (
        <>
          <Icon
            component={tablet_icons.filters_icon}
            className={style.tab_icon}
          />
          <div className={style.tab_label}>
            {ru_en_page_dictionary.filters[language]}
            <br />({metrics.length - excludedIndexes.length})
          </div>
        </>
      ),
    },
    {
      key: 2,
      label: (
        <>
          <Icon
            component={tablet_icons.res_index_icon}
            className={style.tab_icon}
          />{' '}
          <div className={style.tab_label}>
            {ru_en_page_dictionary.index[language]}:
            <br />
            {index.toFixed(2)}
          </div>
        </>
      ),
    },
    {
      key: 3,
      label: (
        <>
          <Icon
            component={tablet_icons.reports_icon}
            className={style.tab_icon}
          />{' '}
          <div className={style.tab_label}>
            {ru_en_page_dictionary.reports[language]}
          </div>
        </>
      ),
    },
  ];

  return (
    <Card
      bodyStyle={{ padding: '5px', height: '100%' }}
      style={{ height: '100%' }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '5px',
        }}
      >
        <Link to="/root">
          <Title
            style={{
              margin: 0,
              lineHeight: '22px',
              fontSize: '18px',
              fontWeight: '600',
            }}
            level={1}
          >
            RESILIENT CITY PLATFORM
          </Title>
        </Link>
        <Link
          to="https://qala-ai.com/"
          target="_blank"
          style={{
            height: 'unset',
            lineHeight: 'normal',
            color: darkMode ? 'white' : 'black',
          }}
        >
          <QalaAIMini />
          {/* <img src={logo} /> */}
        </Link>
      </div>
      <div style={{ display: 'flex', height: 'calc(100% - 32px)' }}>
        <div className={style.nav_wrapper}>
          <div className={style.nav_top_part}>
            {tabs_items.map((item) => {
              return (
                <div
                  key={`tablet-tab-${item.key}`}
                  className={[
                    style.tab_item,
                    activeTab === item.key ? style.tab_item_active : '',
                  ].join(' ')}
                  onClick={() => setActiveTab(item.key)}
                >
                  {item.label}
                </div>
              );
            })}
          </div>
          <div className={style.nav_bottom_part}>
            {userInfo?.perms?.includes('admin') ? (
              <div className={style.tab_item} onClick={toggleTheme}>
                <Icon
                  component={tablet_icons.sun_icon}
                  className={style.tab_icon}
                />
                <div className={style.tab_label}>
                  {ru_en_page_dictionary.theme[language]}
                </div>
              </div>
            ) : (
              ''
            )}
            <Dropdown
              placement="right"
              menu={{
                items: [
                  {
                    key: 'ru',
                    label: 'Русский',
                  },
                  {
                    key: 'en',
                    label: 'English',
                  },
                ],
                onClick: changeLanguage,
              }}
            >
              <div className={style.tab_item}>
                <div className={style.tab_language}>
                  {language === 'ru' ? 'RU' : 'EN'}
                </div>
                <div className={style.tab_label}>
                  {ru_en_page_dictionary.language[language]}
                </div>
              </div>
            </Dropdown>
            <div className={style.tab_item} onClick={logout}>
              <Icon
                component={tablet_icons.exit_icon}
                className={style.tab_icon}
              />
              <div className={style.tab_label}>
                {ru_en_page_dictionary.profile_logout[language]}
              </div>
            </div>
          </div>
        </div>
        <TabletContentManager activeTab={activeTab} />
      </div>
    </Card>
  );
}
