import { useUnit } from 'effector-react';
import { Card, Popover, Radio, Space, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { CheckOutlined, DownOutlined } from '@ant-design/icons';
import style from './MapGradient.module.css';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import {
  $blockedBuckets,
  $bucketAlgorithm,
  $gradientBuckets,
  changeBucketAlgorithmEv,
} from '../../models/gradientModel/index.js';
import { showLoaderEv } from '../../models/webSocketModel/index.js';
import { $mapLoaded } from '../../models/mapModel/index.js';
import { $language, $userInfo } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import index_names_dynamic from '../../dictionaries/index_names_dymanics.json';

export default function MapGradient() {
  const mapLoaded = useUnit($mapLoaded);
  const gradientBuckets = useUnit($gradientBuckets);
  const activeGradientFilter = useUnit($activeFilters).gradient;
  const activeFilters = useUnit($activeFilters);
  const showLoader = useUnit(showLoaderEv);
  const blockedBuckets = useUnit($blockedBuckets);
  const bucketAlgorithm = useUnit($bucketAlgorithm);
  const changeBucketAlgorithm = useUnit(changeBucketAlgorithmEv);
  const userInfo = useUnit($userInfo);
  const activeAlgorithm = useUnit($bucketAlgorithm);
  const language = useUnit($language);

  const [blockLevel, setBlockLevel] = useState('zoom_8');

  const bucketsCopy = gradientBuckets.buckets;

  const changeActiveFilter = useUnit(changeActiveFilterEv);

  const onRadioChange = (e) => {
    changeBucketAlgorithm(e.target.value);
  };

  const algorithmContent = (
    <Radio.Group onChange={onRadioChange} value={bucketAlgorithm}>
      <Space direction="vertical">
        <Radio value="EQI">Equal Intervals (EQI)</Radio>
        <Radio value="JNK">Jenks (JNK)</Radio>
        <Radio value="default">Quantiles</Radio>
        {/* <Radio value="QNT">Quantiles (QNT)</Radio> */}
        {/* <Radio value="GPG">Geometric Progression (GPG)</Radio> */}
        {/* <Radio value="APG">Arithmetic Progression (APG)</Radio> */}
        {/* <Radio value="STD">Standard Deviation (STD)</Radio> */}
      </Space>
    </Radio.Group>
  );

  useEffect(() => {
    if (window.map) {
      if (mapLoaded) {
        window.map.on('render', () => {
          const zoom = window.map.getZoom();
          let level;
          if (zoom < 11) {
            level = 'zoom_8';
          } else if (zoom >= 11 && zoom < 13) {
            level = 'zoom_9';
          } else {
            level = 'zoom_10';
          }
          if (blockLevel !== level) {
            setBlockLevel(level);
          }
        });
      }
    }
  }, [mapLoaded]);

  return (
    <Card
      className={style.gradient_wrapper}
      bodyStyle={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div className={style.gradient_top}>
        <div className={style.gradient_by_wrapper}>
          {ru_en_page_dictionary.gradient_by[language]}:{' '}
          <Tooltip
            title={
              activeFilters.chosen_metrics.length > 0
                ? index_names_dynamic[
                    activeFilters.chosen_metrics[0].split('_')[1]
                  ][`name_${language}`]
                : ru_en_page_dictionary.resilience_index[language]
            }
          >
            {activeFilters.chosen_metrics.length > 0
              ? index_names_dynamic[
                  activeFilters.chosen_metrics[0].split('_')[1]
                ][`name_${language}`]
              : ru_en_page_dictionary.resilience_index[language]}
          </Tooltip>
        </div>
        {userInfo?.perms?.includes('admin') ? (
          <Popover
            title={ru_en_page_dictionary.algorithm[language]}
            content={algorithmContent}
          >
            <div
              style={{
                cursor: 'pointer',
              }}
            >
              {ru_en_page_dictionary.algorithm[language]}: {activeAlgorithm}{' '}
              <DownOutlined />
            </div>
          </Popover>
        ) : (
          ''
        )}
      </div>
      <Space size="small">
        {bucketsCopy.map((item, index) => {
          if (index !== bucketsCopy.length - 1) {
            return (
              <GradientItem
                key={`${item}${index}`}
                maxValue={bucketsCopy[index + 1] || 0}
                minValue={item}
                color={gradientBuckets.colors[index]}
                changeActiveFilter={changeActiveFilter}
                activeGradientFilter={activeGradientFilter}
                showLoader={showLoader}
                blockedBuckets={blockedBuckets[blockLevel]}
                isLast={index === bucketsCopy.length - 2}
              />
            );
          }
        })}
      </Space>
    </Card>
  );
}

function GradientItem({
  color,
  maxValue,
  minValue,
  changeActiveFilter,
  activeGradientFilter,
  blockedBuckets,
  showLoader,
  isLast,
}) {
  const onClick = () => {
    // showLoader();
    changeActiveFilter({
      field: 'gradient',
      value: {
        min: minValue,
        max: maxValue,
      },
    });
  };

  return (
    <div
      className={[
        style.gradient_item,
        activeGradientFilter.some(
          (item) => item.min === minValue && item.max === maxValue
        )
          ? style.active
          : '',
        // !blockedBuckets[`${minValue}-${maxValue}`] ? style.blocked : '',
      ].join(' ')}
      style={{ background: color }}
      // onClick={!blockedBuckets[`${minValue}-${maxValue}`] ? () => {} : onClick}
      onClick={onClick}
    >
      <div className={style.minValue}>
        {new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
          minValue
        )}
      </div>
      {isLast && (
        <div className={style.maxValue}>
          {new Intl.NumberFormat('ru-RU', { notation: 'compact' }).format(
            maxValue
          )}
        </div>
      )}
      <div
        className={style.badge}
        style={{
          opacity: activeGradientFilter.some(
            (item) => item.min === minValue && item.max === maxValue
          )
            ? 1
            : 0,
        }}
      >
        <CheckOutlined style={{ fontSize: '13px' }} />
      </div>
    </div>
  );
}
