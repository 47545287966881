import { useEffect } from 'react';
import { useUnit } from 'effector-react';
import { center } from '@turf/turf';
import { $zoom10Hexagons } from '../../models/zoom10Model/index.js';
import {
  $interactivity,
  $layersOpacity,
  $mapLoaded,
} from '../../models/mapModel/index.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../models/activeFiltersModel/index.js';
import { changePointerOnLayer, flyTo } from '../../utils/mapbox-utils.js';
import { saveHexDataEv } from '../../models/indexCardsModel/index.js';
import { $gradientBuckets } from '../../models/gradientModel/index.js';
import { formDynamicBuckets } from '../../utils/hexagon-utils.js';
import { delayedHideLoaderEv } from '../../models/webSocketModel/index.js';

const mag1 = ['<', ['get', 'index_main'], 30];
const mag2 = [
  'all',
  ['>=', ['get', 'index_main'], 30],
  ['<', ['get', 'index_main'], 60],
];

function handleClick(e) {
  // if (window.map.getZoom() >= 13) {
  const feature = e.features[0];
  console.log('Feature click:: zoom10 hexagon', feature);
  changeActiveFilterEv({
    field: 'zoom10_hex',
    value: {
      id: feature.properties.hex_id,
      centerCoords: center(feature).geometry.coordinates,
    },
  });
  saveHexDataEv({
    level: 'zoom_10',
    data: {
      id: feature.properties.hex_id,
      indexes: feature.properties,
    },
  });
  // flyTo(center(feature).geometry.coordinates, 15);
  // }
}

function handleTouch(e) {
  if (e.originalEvent.touches && e.originalEvent.touches.length === 1) {
    handleClick(e);
  }
}

function Zoom10Layer() {
  const zoom10Hexagons = useUnit($zoom10Hexagons);
  const mapLoaded = useUnit($mapLoaded);
  const selectedZoom10Hexagon = useUnit($activeFilters).zoom10_hex;
  const selectedZoom9Hexagon = useUnit($activeFilters).zoom9_hex;
  const { buckets, colors } = useUnit($gradientBuckets);
  const interactivity = useUnit($interactivity);
  const layersOpacity = useUnit($layersOpacity);

  const createLayer = (update) => {
    const zoom10HexagonData = {
      type: 'FeatureCollection',
      features: zoom10Hexagons,
    };
    const gradientProp = formDynamicBuckets(buckets, colors);

    if (update) {
      window.map.getSource('zoom10_hexagon_source').setData(zoom10HexagonData);
      window.map.setPaintProperty(
        'zoom10_hexagon_layer',
        'fill-color',
        gradientProp
      );
    } else {
      if (!window.map.getSource('zoom10_hexagon_source')) {
        window.map.addSource('zoom10_hexagon_source', {
          type: 'geojson',
          data: zoom10HexagonData,
        });
      }

      if (!window.map.getLayer('zoom10_hexagon_layer')) {
        window.map.addLayer({
          id: 'zoom10_hexagon_layer',
          type: 'fill',
          source: 'zoom10_hexagon_source',
          paint: {
            // 'fill-color': '#FF0000',
            // 'fill-color': [
            //   'case',
            //   mag1,
            //   'rgba(255, 0, 0, 0.5)',
            //   mag2,
            //   'rgba(240, 216, 30, 0.5)',
            //   'rgba(47,255,0,0.5)',
            // ],
            'fill-color': gradientProp,
            // 'fill-opacity': ['interpolate', ['linear'], ['zoom'], 12, 0, 13, 1],
            'fill-opacity': layersOpacity,
          },
        });
      }

      window.map.on('render', () => {
        delayedHideLoaderEv();
      });

      changePointerOnLayer('zoom10_hexagon_layer', true);

      // FIXME Border layer
      // window.map.addLayer({
      //   id: 'zoom10_hexagon_outline',
      //   type: 'line',
      //   source: 'zoom10_hexagon_source',
      //   paint: {
      //     'line-color': 'black',
      //     'line-width': 1,
      //     'line-opacity': ['interpolate', ['linear'], ['zoom'], 9, 0, 10, 0.5],
      //   },
      // });
    }
  };

  useEffect(() => {
    if (mapLoaded && zoom10Hexagons.length && buckets.length > 0) {
      if (window.map.getLayer('zoom10_hexagon_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [zoom10Hexagons, mapLoaded, buckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom10_hexagon_layer')) {
      if (selectedZoom10Hexagon.id !== '') {
        flyTo(selectedZoom10Hexagon.centerCoords, 15);
      } else if (selectedZoom9Hexagon.centerCoords.length > 0) {
        flyTo(selectedZoom9Hexagon.centerCoords, 13);
      }
    }
  }, [selectedZoom10Hexagon.id]);

  useEffect(() => {
    if (interactivity) {
      window.map.on('click', 'zoom10_hexagon_layer', handleClick);
      window.map.on('touchstart', 'zoom10_hexagon_layer', handleTouch);
      changePointerOnLayer('zoom10_hexagon_layer', interactivity);
    } else {
      window.map.off('click', 'zoom10_hexagon_layer', handleClick);
      window.map.off('touchstart', 'zoom10_hexagon_layer', handleTouch);
      changePointerOnLayer('zoom10_hexagon_layer', interactivity);
    }
  }, [interactivity]);

  useEffect(() => {
    if (
      mapLoaded &&
      window.map.getLayer('zoom10_hexagon_layer') &&
      buckets.length > 0
    ) {
      createLayer(true);
    }
  }, [buckets]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('zoom10_hexagon_layer')) {
      setTimeout(() => {
        window.map.setPaintProperty(
          'zoom10_hexagon_layer',
          'fill-opacity',
          layersOpacity
        );
      }, 100);
    }
  }, [mapLoaded, layersOpacity]);

  return null;
}

export default Zoom10Layer;
