import Map from '../Map/Map';
import Zoom10Layer from '../Layers/Zoom10Layer.jsx';
import Zoom9Layer from '../Layers/Zoom9Layer.jsx';
import Zoom8Layer from '../Layers/Zoom8Layer.jsx';
import MapStyleSelector from '../MapStyleSelector/MapStyleSelector.jsx';
import MapControls from '../MapControls/MapControls.jsx';
import MapGradient from '../MapGradient/MapGradient.jsx';
import MapGeoCoder from '../MapGeoCoder/MapGeoCoder.jsx';
import IsochroneModal from '../IsochroneModal/IsochroneModal.jsx';
import IsochroneLayer from '../Layers/IsochroneLayer.jsx';
import MapDrawControls from '../MapDrawControls/MapDrawControls.jsx';
import MapNavControls from '../MapNavControls/MapNavControls.jsx';
import OpacitySlider from '../OpacitySlider/OpacitySlider.jsx';

function MapBlock() {
  return (
    <>
      <Map />
      <MapGeoCoder />
      {/* <Zoom8Layer /> */}
      {/* <Zoom9Layer /> */}
      <Zoom10Layer />
      <IsochroneLayer />
      <MapControls />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          top: '50%',
          left: '15px',
          transform: 'translateY(-50%)',
          rowGap: '20px',
        }}
      >
        <MapNavControls />
        <MapDrawControls />
        <OpacitySlider />
      </div>
      <MapGradient />
      <IsochroneModal />
    </>
  );
}

export default MapBlock;
