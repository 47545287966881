import { sample } from 'effector';
import { $activeFilters } from './stores.js';
import {
  changeActiveFilterEv,
  clearActiveGradientEv,
  clearFiltersEv,
  dbChangeActiveFilterEv,
  debounced,
  selectAllEv,
  submitFiltersBackEv,
  submitFiltersEv,
} from './events.js';
import { all_metrics } from '../../dictionaries/metrics.js';
import { wsGetFilteredPolygons } from '../../utils/webSocketConfig.js';

sample({
  source: $activeFilters,
  clock: [debounced, changeActiveFilterEv],
  fn: (source, clock) => {
    if (/zoom\d+?_hex/g.test(clock.field)) {
      if (source[clock.field].id === clock.value.id) {
        return { ...source, [clock.field]: { id: '', centerCoords: [] } };
      }
      return { ...source, [clock.field]: clock.value };
    }
    if (clock.field === 'excludedIndexes') {
      if (!clock.value) {
        return {
          ...source,
          excludedIndexes: [],
        };
      }
      if (typeof clock.value === 'string') {
        if (source[clock.field].includes(clock.value)) {
          return {
            ...source,
            excludedIndexes: source.excludedIndexes.filter(
              (item) => item !== clock.value
            ),
          };
        }
      } else if (
        clock.value.every((item) => source[clock.field].includes(item))
      ) {
        return {
          ...source,
          excludedIndexes: source.excludedIndexes.filter(
            (item) => !clock.value.includes(item)
          ),
        };
      }
      let addValue = clock.value;
      if (typeof clock.value === 'string') {
        addValue = [clock.value];
      }
      return {
        ...source,
        excludedIndexes: [...source.excludedIndexes, ...addValue],
      };
    }

    if (clock.field === 'gradient') {
      if (
        source.gradient.every(
          (item) => item.min !== clock.value.min && item.max !== clock.value.max
        )
      ) {
        return {
          ...source,
          gradient: [...source.gradient, clock.value],
        };
      }
      return {
        ...source,
        gradient: source.gradient.filter(
          (item) => item.min !== clock.value.min && item.max !== clock.value.max
        ),
      };
    }

    if (clock.field === 'chosen_metrics') {
      if (source.chosen_metrics.includes(clock.value)) {
        return {
          ...source,
          chosen_metrics: source.chosen_metrics.filter(
            (item) => item !== clock.value
          ),
        };
      }
      return {
        ...source,
        // FIXME For multiple metrics. Gotta rewrite calculateHexagonsByMetrics in hex utils
        // chosen_metrics: [...source.chosen_metrics, clock.value],
        chosen_metrics: [clock.value],
      };
    }

    if (source[clock.field] === clock.value) {
      return { ...source, [clock.field]: '' };
    }
    return { ...source, [clock.field]: clock.value };
  },
  target: $activeFilters,
});

sample({
  source: $activeFilters,
  clock: clearFiltersEv,
  fn: (source, clock) => {
    return {
      ...source,
      excludedIndexes: all_metrics,
    };
  },
  target: $activeFilters,
});

sample({
  source: $activeFilters,
  clock: selectAllEv,
  fn: (source, clock) => {
    return {
      ...source,
      excludedIndexes: [],
    };
  },
  target: [$activeFilters, submitFiltersEv],
});

sample({
  source: $activeFilters,
  clock: submitFiltersEv,
  filter: (source) => source.chosen_metrics.length !== 0,
  fn: (source, clock) => {
    return {
      field: 'chosen_metrics',
      value: source.chosen_metrics[0],
    };
  },
  target: changeActiveFilterEv,
});

sample({
  source: $activeFilters,
  clock: submitFiltersBackEv,
  fn: (source) => {
    const payload = {
      region_ids: source.district,
      index_ids: all_metrics
        .filter((item) => !source.excludedIndexes.includes(item))
        .map((item) => Number(item.split('index_')[1])),
    };

    wsGetFilteredPolygons(payload);
  },
});

sample({
  source: $activeFilters,
  clock: clearActiveGradientEv,
  fn: (source) => {
    return {
      ...source,
      gradient: [],
    };
  },
  target: $activeFilters,
});
