import { Card, Col, Row, Space } from 'antd';
import { useUnit } from 'effector-react';
import style from './MainPageRestyle.module.css';
import LeftBar from '../../components/LeftBar/LeftBar.jsx';
import MapBlock from '../../components/MapBlock/MapBlock.jsx';
import TopBar from '../../components/TopBar/TopBar.jsx';
import SideModal from '../../components/SideModal/SideModal.jsx';
import { $isTablet } from '../../models/authModel/index.js';
import TabletLeftBar from '../../components/TabletLeftBar/TabletLeftBar.jsx';

export default function MainPageRestyle() {
  const isTablet = useUnit($isTablet);
  if (isTablet) {
    return (
      <Row gutter={[5, 0]} style={{ padding: '5px 0px', height: '100%' }}>
        <Col style={{ width: '385px', maxHeight: '100%' }}>
          <TabletLeftBar />
        </Col>
        <Col style={{ width: 'calc(100% - 385px)' }}>
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </Col>
      </Row>
    );
  }
  return (
    <Row gutter={[20, 0]} style={{ padding: '20px 0px', height: '100%' }}>
      <Col style={{ width: '470px' }}>
        <LeftBar />
      </Col>
      <Col style={{ width: 'calc(100% - 470px)' }}>
        <Space id="top_bar" className={style.top_bar} direction="vertical">
          <TopBar />
          <Card className="map_card" bodyStyle={{ height: '100%' }}>
            <MapBlock />
          </Card>
        </Space>
      </Col>
      <SideModal />
    </Row>
  );
}
