import { createEvent } from 'effector';
import { debounce } from 'patronum';

export const changeActiveFilterEv = createEvent();
export const dbChangeActiveFilterEv = createEvent();

export const clearFiltersEv = createEvent();

export const submitFiltersEv = createEvent();

export const selectAllEv = createEvent();

export const calculateByMetricEv = createEvent();

export const debounced = debounce({
  source: dbChangeActiveFilterEv,
  timeout: 1000,
});

export const submitFiltersBackEv = createEvent();

export const clearActiveGradientEv = createEvent();
